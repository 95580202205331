import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { Section } from "./common/TextStyle";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";

const Home: React.FC = () => {
  const navigate = useNavigate();

  const handleSummaryClick = () => {
    navigate("/count");
  };

  const handlePreviewClick = () => {
    navigate("/search");
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  return (
    <>
      <Helmet>
        <title>{"Smash Counter | スマブラSP集計サイト"}</title>
        <meta
          name="description"
          content={
            "当サイトはスマブラSP(Super Smash Bros Ultimate)におけるキャラクターの振った技の回数の集計、集計データの登録、集計データの参照機能を提供します。"
          }
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="google-adsense-account" content="ca-pub-8034328441383028" />
      </Helmet>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 2, sm: 4 },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            textAlign: "center",
            fontSize: "clamp(3.5rem, 10vw, 4rem)",
          }}
        >
          Smash&nbsp;
          <Typography
            component="span"
            variant="h1"
            sx={{
              fontSize: "clamp(3rem, 10vw, 4rem)",
              color: (theme) =>
                theme.palette.mode === "light"
                  ? "primary.main"
                  : "primary.light",
            }}
          >
            Counter
          </Typography>
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", gap: 6 }}>
          <Button
            variant="contained"
            startIcon={
              <BorderColorIcon
                sx={{
                  width: { xs: "20px", sm: "30px", md: "40px" },
                  height: { xs: "20px", sm: "30px", md: "40px" },
                }}
              />
            }
            sx={{
              backgroundColor: "primary",
              width: { xs: "120px", sm: "150px", md: "200px" },
              height: { xs: "120px", sm: "150px", md: "200px" },

              mt: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleSummaryClick}
          >
            <Typography
              variant="h6"
              sx={{
                mt: "auto",
                mb: 1,
                fontSize: { xs: "1rem", sm: "1rem", md: "1.25rem" },
              }}
            >
              集計
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: "auto",
                fontSize: { xs: "0.6rem", sm: "0.75rem", md: "1rem" },
              }}
            >
              振った技の回数を<br></br>カウントします
            </Typography>
          </Button>
          <Button
            variant="outlined"
            startIcon={
              <SearchIcon
                sx={{
                  width: { xs: "20px", sm: "30px", md: "40px" },
                  height: { xs: "20px", sm: "30px", md: "40px" },
                }}
              />
            }
            sx={{
              width: { xs: "120px", sm: "150px", md: "200px" },
              height: { xs: "120px", sm: "150px", md: "200px" },

              mt: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handlePreviewClick}
          >
            <Typography
              variant="h6"
              sx={{
                mt: "auto",
                mb: 1,
                fontSize: { xs: "1rem", sm: "1rem", md: "1.25rem" },
              }}
            >
              参照
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: "auto",
                fontSize: { xs: "0.6rem", sm: "0.75rem", md: "1rem" },
              }}
            >
              登録済みデータを<br></br>参照します
            </Typography>
          </Button>
        </Box>
        <Section title="概要">
          当サイトはスマブラSPにおけるキャラクターの振った技の回数の集計、集計データの登録、集計データの参照機能を提供します。
          <br />
          主に攻撃技や回避、崖上がりなどを集計する機能を備えています。それ以外で集計したい項目がある場合は
          <br />
          ユーザー側で項目を追加することもできます。
          <br />
          当サイトは集計データありきのサービスなのでユーザー様の協力が必須となります。
          <br />
          <span style={{ color: "#F44242" }}>
            ※集計データの登録にはアカウントが必要ですが簡単にできますので是非ご協力をお願いします。(参照機能では必要ありません)
          </span>
          <br />
          みなさんでデータを集計して参照しあい、より良いスマブラライフを送っていただければ幸いです。
          <br />
          <br />
          当サイトでは試合のレベル帯の指標としてクマメイト様、スマメイト様のレーティングを採用しています。
          <br />
          <a href="https://kumamate.net/vip/">クマメイト様</a>
          <br />
          <a href="https://smashmate.net/">スマメイト様</a>
          <br />
          <br />
          更新情報などはX(旧Twitter)より発信していますのでそちらをご確認ください。画面下部のXアイコンから飛べます。
        </Section>
        <Section title="解説動画">
          YouTubeにて解説動画(使用手順)を出していますので是非ご確認ください。
          <br />
          <a href="https://www.youtube.com/watch?v=zxtwjdDlk0o&t=196s">
            解説動画
          </a>
          <br />
        </Section>
      </Container>
    </>
  );
};

export default Home;
