import * as React from "react";
import { PaletteMode } from "@mui/material";
import AppAppBar from "../components/common/AppAppBar";
import Footer from "../components/common/Footer";
import SearchForm from "../components/common/SearchForm";
import { useContext } from "react";
import { AuthContext } from "../AuthContext";

export default function MydataEditPage() {
  const [mode, setMode] = React.useState<PaletteMode>("light");
  const authContext = useContext(AuthContext);
  const { username } = authContext!;

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <>
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <SearchForm username={username} editFlg={true} />
      <Footer />
    </>
  );
}
