import { createContext, useState, ReactNode } from "react";
import api from "./api";
import axios from "axios";
import { useEffect } from "react";
import { Grid } from "react-loader-spinner";

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  username: string;
  getUsername: () => void;
  loginUser: (username: string, password: string) => Promise<void>;
  logoutUser: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [username, setUsername] = useState<string>("");

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await api.post("/api/auth/");
        // 認証成功
        setIsAuthenticated(true);
        await getUsername();
      } catch (error) {
        // 認証失敗
        setIsAuthenticated(false);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };

    checkAuth();
  }, []);

  const loginUser = async (email: string, password: string) => {
    const response = await axios.post("/api/login/", {
      email,
      password,
    });
    await getUsername();
    setIsAuthenticated(true);
  };

  const logoutUser = async () => {
    const response = await axios.post("/api/logout/");
    setIsAuthenticated(false);
    setUsername("");
  };

  const getUsername = async () => {
    const response = await axios.get("/api/get-user/");
    setUsername(response.data.username);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid
          visible={true}
          height="60"
          width="60"
          color="#3b82f6"
          ariaLabel="grid-loading"
          radius="12.5"
          wrapperStyle={{}}
          wrapperClass="grid-wrapper"
        />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        username,
        getUsername,
        loginUser,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
