import * as React from "react";
import { PaletteMode } from "@mui/material";
import AppAppBar from "../components/common/AppAppBar";
import Count from "../components/common/Count";
import Footer from "../components/common/Footer";
import {
  SearchResult,
  CountData,
  CountType,
  BurstData,
  BurstedData,
  initialCountData,
} from "../common/constants";
import CountNote from "../components/CountNote";
export default function CountPage() {
  const [mode, setMode] = React.useState<PaletteMode>("light");

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const attackType: CountType = "normal";
  const emptyBurstData: BurstData[] = [];
  const emptyBurstedData: BurstedData[] = [];

  // 空の SearchResult オブジェクトを作成
  const emptySearchResult: SearchResult = {
    id: "",
    my_character: "",
    counts: initialCountData,
    opponent_character: "",
    level: "",
    match_type: "",
    battle_date: "",
    outcome: "",
    battle_type: "",
    stage: "",
    is_public: false,
    video_url: "",
    comments: "",
    burst_data: emptyBurstData,
    bursted_data: emptyBurstedData,
    match_time: { minutes: "", seconds: "" },
  };

  return (
    <>
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Count
        editInfo={emptySearchResult}
        editFlg={false}
        editDisplayedCountType={attackType}
      />
      <CountNote />
      <Footer />
    </>
  );
}
