import { useEffect, useRef } from "react";
import {
  CountType,
  twoNormalAttacksChara,
  threeNormalAttacksChara,
  PercentType,
} from "../../common/constants";

type KeyboardHandlerProps = {
  myCharacter: string;
  displayedCountType: string;
  setDisplayedCountType: (type: CountType) => void;
  displayedDamagePercentType: string;
  setDisplayedDamagePercentType: (type: PercentType) => void;
  handleIncrement: (attackType: string) => void;
  highlightField: (field: string) => void;
};

export const useKeyboardHandler = ({
  myCharacter,
  displayedCountType,
  setDisplayedCountType,
  displayedDamagePercentType,
  setDisplayedDamagePercentType,
  handleIncrement,
  highlightField,
}: KeyboardHandlerProps) => {
  const arrowKeyPressed = useRef(false);
  const lastArrowKey = useRef("");
  const arrowKeyTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (myCharacter === "") {
      return;
    }

    const handleKeyPress = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();

      if (["arrowup", "arrowdown", "arrowleft", "arrowright"].includes(key)) {
        event.preventDefault();
        arrowKeyPressed.current = true;
        lastArrowKey.current = key;

        // 1秒後にリセット
        if (arrowKeyTimer.current) {
          clearTimeout(arrowKeyTimer.current);
        }
        arrowKeyTimer.current = setTimeout(() => {
          arrowKeyPressed.current = false;
          lastArrowKey.current = "";
        }, 1000);
      } else if (key === "tab") {
        handleTabPress(
          displayedCountType,
          setDisplayedCountType,
          myCharacter,
          event
        );
      } else if (key === "space" || key === " ") {
        handleSpacePress(
          displayedDamagePercentType,
          setDisplayedDamagePercentType,
          event
        );
      } else if (key === "a") {
        handleAPress(
          displayedCountType,
          arrowKeyPressed,
          lastArrowKey,
          handleIncrement,
          highlightField
        );
      } else if (key === "q") {
        if (displayedCountType.includes("normal")) {
          handleIncrement("DA");
          highlightField("DA");
        } else if (displayedCountType === "other") {
          handleIncrement("攻撃上がり");
          highlightField("攻撃上がり");
        }
      } else if (key === "s") {
        handleSPress(
          displayedCountType,
          arrowKeyPressed,
          lastArrowKey,
          handleIncrement,
          highlightField
        );
      } else if (key === "d") {
        handleDPress(
          displayedCountType,
          arrowKeyPressed,
          lastArrowKey,
          handleIncrement,
          highlightField
        );
      } else if (key === "w") {
        handleWPress(
          displayedCountType,
          arrowKeyPressed,
          lastArrowKey,
          handleIncrement,
          highlightField
        );
      } else if (key === "x") {
        handleXPress(
          displayedCountType,
          arrowKeyPressed,
          lastArrowKey,
          handleIncrement,
          highlightField
        );
      }

      // リセット処理
      if (["a", "s", "d", "w", "x"].includes(key)) {
        arrowKeyPressed.current = false;
        lastArrowKey.current = "";
        if (arrowKeyTimer.current) {
          clearTimeout(arrowKeyTimer.current);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      if (arrowKeyTimer.current) {
        clearTimeout(arrowKeyTimer.current);
      }
    };
  });
};

const handleTabPress = (
  displayedCountType: string,
  setDisplayedCountType: (type: CountType) => void,
  myCharacter: string,
  event: KeyboardEvent
) => {
  event.preventDefault();

  if (displayedCountType === "normal1") {
    setDisplayedCountType("normal2");
  } else if (displayedCountType === "normal2") {
    if (twoNormalAttacksChara.includes(myCharacter)) {
      setDisplayedCountType("normal1");
    } else if (threeNormalAttacksChara.includes(myCharacter)) {
      setDisplayedCountType("normal3");
    }
  } else if (displayedCountType === "normal3") {
    setDisplayedCountType("normal1");
  }
};

const handleSpacePress = (
  displayedDamagePercentType: string,
  setDisplayedDamagePercentType: (type: PercentType) => void,
  event: KeyboardEvent
) => {
  event.preventDefault();

  if (displayedDamagePercentType === "low") {
    setDisplayedDamagePercentType("middle");
  } else if (displayedDamagePercentType === "middle") {
    setDisplayedDamagePercentType("high");
  } else if (displayedDamagePercentType === "high") {
    setDisplayedDamagePercentType("low");
  }
};

const handleAPress = (
  displayedCountType: string,
  arrowKeyPressed: React.MutableRefObject<boolean>,
  lastArrowKey: React.MutableRefObject<string>,
  handleIncrement: (attackType: string) => void,
  highlightField: (field: string) => void
) => {
  if (arrowKeyPressed.current) {
    if (displayedCountType.includes("normal")) {
      switch (lastArrowKey.current) {
        case "arrowleft":
        case "arrowright":
          handleIncrement("横強");
          highlightField("横強");
          break;
        case "arrowup":
          handleIncrement("上強");
          highlightField("上強");
          break;
        case "arrowdown":
          handleIncrement("下強");
          highlightField("下強");
          break;
      }
    }
  } else {
    if (displayedCountType.includes("normal")) {
      handleIncrement("弱");
      highlightField("弱");
    } else if (displayedCountType === "other") {
      handleIncrement("ガード");
      highlightField("ガード");
    }
  }
};

// Sキーの処理
const handleSPress = (
  displayedCountType: string,
  arrowKeyPressed: React.MutableRefObject<boolean>,
  lastArrowKey: React.MutableRefObject<string>,
  handleIncrement: (attackType: string) => void,
  highlightField: (field: string) => void
) => {
  if (arrowKeyPressed.current) {
    if (displayedCountType.includes("normal")) {
      switch (lastArrowKey.current) {
        case "arrowleft":
        case "arrowright":
          handleIncrement("横スマ");
          highlightField("横スマ");

          break;
        case "arrowup":
          handleIncrement("上スマ");
          highlightField("上スマ");
          break;
        case "arrowdown":
          handleIncrement("下スマ");
          highlightField("下スマ");
          break;
      }
    } else if (displayedCountType === "other") {
      switch (lastArrowKey.current) {
        case "arrowleft":
        case "arrowright":
          handleIncrement("地上移動回避");
          highlightField("地上移動回避");
          break;
      }
    }
  } else {
    if (displayedCountType === "other") {
      handleIncrement("地上その場回避");
      highlightField("地上その場回避");
    }
  }
};

// Dキーの処理
const handleDPress = (
  displayedCountType: string,
  arrowKeyPressed: React.MutableRefObject<boolean>,
  lastArrowKey: React.MutableRefObject<string>,
  handleIncrement: (attackType: string) => void,
  highlightField: (field: string) => void
) => {
  if (arrowKeyPressed.current) {
    if (displayedCountType.includes("normal")) {
      switch (lastArrowKey.current) {
        case "arrowleft":
        case "arrowright":
          handleIncrement("横B");
          highlightField("横B");
          break;
        case "arrowup":
          handleIncrement("上B");
          highlightField("上B");
          break;
        case "arrowdown":
          handleIncrement("下B");
          highlightField("下B");
          break;
      }
    } else if (displayedCountType === "other") {
      switch (lastArrowKey.current) {
        case "arrowleft":
        case "arrowright":
          handleIncrement("転がり上がり");
          highlightField("転がり上がり");
          break;
        case "arrowup":
          handleIncrement("ジャンプ上がり");
          highlightField("ジャンプ上がり");
          break;
        case "arrowdown":
          handleIncrement("崖はなし");
          highlightField("崖はなし");
          break;
      }
    }
  } else {
    if (displayedCountType.includes("normal")) {
      handleIncrement("NB");
      highlightField("NB");
    } else if (displayedCountType === "other") {
      handleIncrement("その場上がり");
      highlightField("その場上がり");
    }
  }
};

// Wキーの処理
const handleWPress = (
  displayedCountType: string,
  arrowKeyPressed: React.MutableRefObject<boolean>,
  lastArrowKey: React.MutableRefObject<string>,
  handleIncrement: (attackType: string) => void,
  highlightField: (field: string) => void
) => {
  if (arrowKeyPressed.current) {
    if (displayedCountType.includes("normal")) {
      switch (lastArrowKey.current) {
        case "arrowright":
          handleIncrement("空前");
          highlightField("空前");
          break;
        case "arrowleft":
          handleIncrement("空後");
          highlightField("空後");
          break;
        case "arrowup":
          handleIncrement("空上");
          highlightField("空上");
          break;
        case "arrowdown":
          handleIncrement("空下");
          highlightField("空下");
          break;
      }
    } else if (displayedCountType === "other") {
      switch (lastArrowKey.current) {
        case "arrowright":
        case "arrowleft":
          handleIncrement("空中移動回避");
          highlightField("空中移動回避");
          break;
      }
    }
  } else {
    if (displayedCountType.includes("normal")) {
      handleIncrement("空N");
      highlightField("空N");
    } else if (displayedCountType === "other") {
      handleIncrement("空中N回避");
      highlightField("空中N回避");
    }
  }
};

// Xキーの処理
const handleXPress = (
  displayedCountType: string,
  arrowKeyPressed: React.MutableRefObject<boolean>,
  lastArrowKey: React.MutableRefObject<string>,
  handleIncrement: (attackType: string) => void,
  highlightField: (field: string) => void
) => {
  if (arrowKeyPressed.current) {
    if (displayedCountType.includes("normal")) {
      switch (lastArrowKey.current) {
        case "arrowright":
          handleIncrement("前投げ");
          highlightField("前投げ");
          break;
        case "arrowleft":
          handleIncrement("後ろ投げ");
          highlightField("後ろ投げ");
          break;
        case "arrowup":
          handleIncrement("上投げ");
          highlightField("上投げ");
          break;
        case "arrowdown":
          handleIncrement("下投げ");
          highlightField("下投げ");
          break;
      }
    }
  } else {
    if (displayedCountType.includes("normal")) {
      handleIncrement("掴み(投げなし)");
      highlightField("掴み(投げなし)");
    }
  }
};
