import * as React from "react";
import { PaletteMode } from "@mui/material";
import AppAppBar from "../components/common/AppAppBar";
import Footer from "../components/common/Footer";
import TermUse from "../components/TermUse";

export default function TermUsePage() {
  const [mode, setMode] = React.useState<PaletteMode>("light");

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <>
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <TermUse />
      <Footer />
    </>
  );
}
