import BarChartIcon from "@mui/icons-material/BarChart";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { Chart, registerables } from "chart.js";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Helmet } from "react-helmet-async";
import api from "../../api";
import { AuthContext } from "../../AuthContext";
import {
  CountType,
  CountData,
  gameDivItems,
  levelItems,
  stageItems,
  names,
  normalAttacks,
  otherCounts,
  SearchResult,
  specialAttacksMap,
  burstConditionOptions,
  BurstData,
  BurstedData,
  minutesOptions,
  secondsOptions,
  twoNormalAttacksChara,
  threeNormalAttacksChara,
  PercentType,
  initialCountData,
  PercentItem,
} from "../../common/constants";
import { chartOptions } from "../../common/graph";
import { scrollToSection } from "../../common/scroll";
import BackDrop from "./BackDrop";
import CountTypeToggleButtonGroup from "./CountTypeToggleGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useKeyboardHandler } from "./useKeyboardHandler";

Chart.register(...registerables);

const Count: FC<{
  editInfo: SearchResult;
  editFlg: boolean;
  editDisplayedCountType: CountType;
}> = ({ editInfo, editFlg, editDisplayedCountType }) => {
  const [myCharacter, setMyCharacter] = useState<string>("");
  const [highlightedAttack, setHighlightedAttack] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showChart, setShowChart] = useState<boolean>(false);
  const [displayedCountType, setDisplayedCountType] =
    useState<CountType>("normal");
  const [displayedDamagePercentType, setDisplayedDamagePercentType] =
    useState<PercentType>("low");
  const [counts, setCounts] = useState<{
    [character: string]: CountData;
  }>({});
  const [open, setOpen] = useState<boolean>(false);
  const [opponentCharacter, setOpponentCharacter] = useState<string>("");
  const [level, setLevel] = useState("");
  const [matchType, setMatchType] = useState("");
  const [date, setDate] = useState<string | null>(null);
  const [battleType, setBattleType] = useState<string>("");
  const [outcome, setOutcome] = useState<string>("");
  const [stage, setStage] = useState<string>("");
  const [matchTime, setMatchTime] = useState({ minutes: "", seconds: "" });
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [isPublic, setIsPublic] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [userdefAttacks, setUserdefAttacks] = useState<{
    [key: string]: string[];
  }>({});
  const [newAttackName, setNewAttackName] = useState<string>("");
  const [checkedItems, setCheckedItems] = useState<{
    [key: string]: boolean;
  }>({});
  const [errors, setErrors] = useState({
    opponentCharacter: false,
    level: false,
    matchType: false,
    date: false,
    battleType: false,
    outcome: false,
    stage: false,
    matchTime: false,
  });
  const [burstData, setBurstData] = useState<BurstData[]>([
    {
      burstAttack: "",
      burstCondition: "",
      burstPercentage: "",
      burstcomment: "",
    },
  ]);
  const [burstedData, setBurstedData] = useState<BurstedData[]>([
    {
      burstedAttack: "",
      burstedCondition: "",
      burstedPercentage: "",
      burstedcomment: "",
    },
  ]);

  const authContext = useContext(AuthContext);
  const { username, isAuthenticated } = authContext!;

  const getSpecialAttacks = (myCharacter: string): string[] =>
    specialAttacksMap[myCharacter] || [];
  const countItems = displayedCountType.includes("normal")
    ? normalAttacks
    : displayedCountType === "userdef"
    ? userdefAttacks[myCharacter]
    : displayedCountType === "other"
    ? otherCounts
    : getSpecialAttacks(myCharacter);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleCharacterChange = (myCharacter: string) => {
    if (myCharacter) {
      if (twoNormalAttacksChara.includes(myCharacter)) {
        setDisplayedCountType("normal1");
      } else if (threeNormalAttacksChara.includes(myCharacter)) {
        setDisplayedCountType("normal1");
      } else {
        setDisplayedCountType("normal");
      }
      setMyCharacter(myCharacter);
    }

    // 初期化処理
    if (myCharacter && !counts[myCharacter]) {
      const initialCounts: {
        [attackType in CountType]: {
          [key in PercentType]: {
            [countName: string]: number;
          };
        };
      } = initialCountData;

      normalAttacks.forEach((attack) => {
        PercentItem.forEach((percent) => {
          initialCountData.normal[percent][attack] = 0;
        });
      });

      otherCounts.forEach((attack) => {
        PercentItem.forEach((percent) => {
          initialCountData.other[percent][attack] = 0;
        });
      });

      getSpecialAttacks(myCharacter).forEach((attack) => {
        PercentItem.forEach((percent) => {
          initialCountData.special[percent][attack] = 0;
        });
      });

      if (twoNormalAttacksChara.includes(myCharacter)) {
        normalAttacks.forEach((attack) => {
          PercentItem.forEach((percent) => {
            initialCountData.normal1[percent][attack] = 0;
            initialCountData.normal2[percent][attack] = 0;
          });
        });
      } else if (threeNormalAttacksChara.includes(myCharacter)) {
        normalAttacks.forEach((attack) => {
          PercentItem.forEach((percent) => {
            initialCountData.normal1[percent][attack] = 0;
            initialCountData.normal2[percent][attack] = 0;
            initialCountData.normal3[percent][attack] = 0;
          });
        });
      }

      setCounts((prevCounts) => ({
        ...prevCounts,
        [myCharacter]: {
          ...prevCounts[myCharacter],
          ...initialCounts,
        },
      }));

      setUserdefAttacks((prevUserdefAttacks) => ({
        ...prevUserdefAttacks,
        [myCharacter]: [],
      }));
    }
  };

  const chartData = {
    labels: countItems,
    datasets: [
      {
        label: "～50%",
        data: countItems.map(
          (countName) =>
            counts[myCharacter]?.[displayedCountType]?.low[countName] || 0
        ),
        backgroundColor: "rgba(66,133,244,1)",
        borderWidth: 1,
        borderColor: "rgba(66,133,244,1)",
      },
      {
        label: "51%～100%",
        data: countItems.map(
          (countName) =>
            counts[myCharacter]?.[displayedCountType]?.middle[countName] || 0
        ),
        backgroundColor: "rgba(244, 180, 0, 1)",
        borderWidth: 1,
        borderColor: "rgba(244, 180, 0, 1)",
      },
      {
        label: "101%～",
        data: countItems.map(
          (countName) =>
            counts[myCharacter]?.[displayedCountType]?.high[countName] || 0
        ),
        backgroundColor: "rgba(234, 67, 53, 1)",
        borderWidth: 1,
        borderColor: "rgba(234, 67, 53, 1)",
      },
    ],
  };

  const handleIncrement = (countName: string) => {
    const newCounts = { ...counts };
    newCounts[myCharacter][displayedCountType][displayedDamagePercentType][
      countName
    ] += 1;

    setErrorMessage("");
    if (
      newCounts[myCharacter][displayedCountType][displayedDamagePercentType][
        countName
      ] > 999
    ) {
      setErrorMessage("入力できる上限値は999までです。");
      scrollToSection("count-info");
      // 999を超える場合は増加前の値に戻す
      newCounts[myCharacter][displayedCountType][displayedDamagePercentType][
        countName
      ] -= 1;
    }

    setCounts(newCounts);
  };

  const handleDecrement = (countName: string) => {
    const newCounts = { ...counts };
    if (
      newCounts[myCharacter][displayedCountType][displayedDamagePercentType][
        countName
      ] > 0
    ) {
      newCounts[myCharacter][displayedCountType][displayedDamagePercentType][
        countName
      ] -= 1;
      setCounts(newCounts);
    }
  };

  const highlightField = (attack: string) => {
    setHighlightedAttack(attack);
    setTimeout(() => {
      setHighlightedAttack("");
    }, 500); // 0.5秒間ハイライト
  };

  const validateFields = () => {
    const newErrors = {
      opponentCharacter: opponentCharacter === "",
      level: level === "",
      matchType: matchType === "",
      date: date === null,
      battleType: battleType === "",
      outcome: outcome === "",
      stage: stage === "",
      matchTime: matchTime.minutes === "" || matchTime.seconds === "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    countName: string
  ) => {
    const newValue = parseInt(event.target.value, 10);

    setErrorMessage("");
    if (newValue > 999) {
      setErrorMessage("入力できる上限値は999までです。");
      scrollToSection("count-info");
      return;
    }
    setCounts((prevCounts) => ({
      ...prevCounts,
      [myCharacter]: {
        ...prevCounts[myCharacter],
        [displayedCountType]: {
          ...prevCounts[myCharacter][displayedCountType],
          [displayedDamagePercentType]: {
            ...prevCounts[myCharacter][displayedCountType][
              displayedDamagePercentType
            ],
            [countName]: newValue, // 新しい値を設定
          },
        },
      },
    }));
  };

  const handleAddUserdefAttack = (countName: string) => {
    setErrorMessage("");

    if (!newAttackName) {
      setErrorMessage("追加する項目が未入力です。");
      scrollToSection("count-info");
      return;
    }

    if (userdefAttacks[myCharacter]?.includes(countName)) {
      setErrorMessage("追加する項目が既に登録されています。");
      scrollToSection("count-info");
      return;
    }

    if (userdefAttacks[myCharacter].length > 30) {
      setErrorMessage("追加できる項目数の上限は30個までです。");
      scrollToSection("count-info");
      return;
    }

    setUserdefAttacks((prevUserdefAttacks) => ({
      ...prevUserdefAttacks,
      [myCharacter]: [...(prevUserdefAttacks[myCharacter] || []), countName],
    }));

    setCounts((prevCounts) => ({
      ...prevCounts,
      [myCharacter]: {
        ...prevCounts[myCharacter],
        [displayedCountType]: {
          ...prevCounts[myCharacter][displayedCountType],
          low: {
            [countName]: 0, // lowに0を設定
          },
          middle: {
            [countName]: 0, // middleに0を設定
          },
          high: {
            [countName]: 0, // highに0を設定
          },
        },
      },
    }));
  };

  const handleCountTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newCountType: CountType | null
  ) => {
    if (newCountType !== null) {
      setDisplayedCountType(newCountType);
    }
  };

  const handleDamagePercentTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newCountType: PercentType | null
  ) => {
    if (newCountType !== null) {
      setDisplayedDamagePercentType(newCountType);
    }
  };

  const handleCheckChange = (countName: string) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [countName]: !prevCheckedItems[countName],
    }));
  };

  const handleDeleteCheckedItems = () => {
    const updatedUserdefAttacks = userdefAttacks[myCharacter].filter(
      (attack) => !checkedItems[attack]
    );

    setUserdefAttacks((prevUserdefAttacks) => ({
      ...prevUserdefAttacks,
      [myCharacter]: updatedUserdefAttacks,
    }));

    setCounts((prevCounts) => {
      const updatedCounts = { ...prevCounts };
      Object.keys(checkedItems).forEach((attack) => {
        if (checkedItems[attack]) {
          delete updatedCounts[myCharacter][displayedCountType][
            displayedDamagePercentType
          ][attack];
        }
      });
      return updatedCounts;
    });

    setCheckedItems({});
  };

  const burstAttackOptions = normalAttacks
    .concat(
      specialAttacksMap[myCharacter] ? specialAttacksMap[myCharacter] : []
    )
    .concat("その他");

  const burstedAttackOptions = normalAttacks
    .concat(
      specialAttacksMap[opponentCharacter]
        ? specialAttacksMap[opponentCharacter]
        : []
    )
    .concat("その他");

  const handleBurstInputChange = (
    index: number,
    field: keyof BurstData,
    value: string
  ) => {
    const updatedData = [...burstData];
    if (field === "burstPercentage") {
      // 999以上の値を入力できないようにする
      const limitedValue = Math.min(Number(value), 999);
      updatedData[index][field] = limitedValue.toString();
    } else {
      updatedData[index][field] = value;
    }
    setBurstData(updatedData);
  };

  const handleBurstedInputChange = (
    index: number,
    field: keyof BurstedData,
    value: string
  ) => {
    const updatedData = [...burstedData];
    if (field === "burstedPercentage") {
      // 999以上の値を入力できないようにする
      const limitedValue = Math.min(Number(value), 999);
      updatedData[index][field] = limitedValue.toString();
    } else {
      updatedData[index][field] = value;
    }
    setBurstedData(updatedData);
  };

  const handleAddBurstRow = () => {
    setBurstData([
      ...burstData,
      {
        burstAttack: "",
        burstCondition: "",
        burstPercentage: "",
        burstcomment: "",
      },
    ]);
  };

  const handleRemoveBurstRow = (index: number) => {
    const updatedData = burstData.filter((_, i) => i !== index);
    setBurstData(updatedData);
  };

  const handleAddBurstedRow = () => {
    setBurstedData([
      ...burstedData,
      {
        burstedAttack: "",
        burstedCondition: "",
        burstedPercentage: "",
        burstedcomment: "",
      },
    ]);
  };

  const handleRemoveBurstedRow = (index: number) => {
    const updatedData = burstedData.filter((_, i) => i !== index);
    setBurstedData(updatedData);
  };

  // キーボード入力処理
  useKeyboardHandler({
    myCharacter,
    displayedCountType,
    setDisplayedCountType,
    displayedDamagePercentType,
    setDisplayedDamagePercentType,
    handleIncrement,
    highlightField,
  });

  const handleClickOpen = () => {
    setErrorMessage("");
    if (!isAuthenticated) {
      setErrorMessage(
        "アカウント認証が実行できません。アカウントを既にお持ちの方はログイン、お持ちでない方はアカウント作成ください。"
      );
      scrollToSection("count-info");
      return;
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const sendData = {
      username: username,
      my_character: myCharacter,
      counts: counts[myCharacter],
      opponent_character: opponentCharacter,
      level: level,
      match_type: matchType,
      battle_date: date,
      battle_type: battleType,
      outcome: outcome,
      stage: stage,
      is_public: isPublic,
      video_url: videoUrl,
      comments: comments,
      burst_data: burstData,
      bursted_data: burstedData,
      match_time: matchTime,
    };

    setIsLoading(true);

    api
      .post("/api/save-counts/", sendData)
      .then((response) => {
        setIsLoading(false);
        setSuccessMessage(response.data.message);
        scrollToSection("count-info");
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          setIsLoading(false);
          setErrorMessage(
            error.response?.data.detail || "エラーが発生しました。"
          );
          scrollToSection("count-info");
        }
      });

    handleClose();
  };

  const handleUpdate = () => {
    const sendData = {
      id: editInfo.id,
      username: username,
      my_character: myCharacter,
      counts: counts[myCharacter],
      opponent_character: opponentCharacter,
      level: level,
      match_type: matchType,
      battle_date: date,
      battle_type: battleType,
      outcome: outcome,
      stage: stage,
      is_public: isPublic,
      video_url: videoUrl,
      comments: comments,
      burst_data: burstData,
      bursted_data: burstedData,
      match_time: matchTime,
    };

    setIsLoading(true);

    api
      .post("/api/update-counts/", sendData)
      .then((response) => {
        setIsLoading(false);
        setSuccessMessage(response.data.message);
        scrollToSection("count-info");
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          console.log(error);
          setErrorMessage(
            error.response?.data.detail || "エラーが発生しました。"
          );
          scrollToSection("count-info");
          setIsLoading(false);
        }
      });

    handleClose();
  };

  const handleSubmitWithValidation = () => {
    if (validateFields()) {
      handleSubmit();
    }
  };

  const handleUpdateWithValidation = () => {
    if (validateFields()) {
      handleUpdate();
    }
  };

  useEffect(() => {
    if (editFlg) {
      setCounts((prevCounts) => ({
        ...prevCounts,
        [editInfo.my_character]: editInfo.counts,
      }));
      setMyCharacter(editInfo.my_character);
      setOpponentCharacter(editInfo.opponent_character);
      setLevel(editInfo.level);
      setMatchType(editInfo.match_type);
      setDate(editInfo.battle_date);
      setBattleType(editInfo.battle_type);
      setOutcome(editInfo.outcome);
      setStage(editInfo.stage);
      setIsPublic(editInfo.is_public);
      setVideoUrl(editInfo.video_url);
      setComments(editInfo.comments);
      setDisplayedCountType(editDisplayedCountType);
      setBurstData(editInfo.burst_data);
      setBurstedData(editInfo.bursted_data);
      setMatchTime(editInfo.match_time);

      let userdefAttackNameArr: string[] = [];
      Object.keys(editInfo.counts).forEach((key) => {
        if (key === "userdef") {
          const userdefCounts = editInfo.counts[key as CountType];

          // userdefの各レベル（low, middle, high）を処理
          Object.keys(userdefCounts).forEach((subKey) => {
            const levelCounts = userdefCounts[subKey as PercentType];

            // levelCountsからcountNameを取得
            if (subKey === "low") {
              Object.keys(levelCounts).forEach((countName) => {
                userdefAttackNameArr.push(countName); // countNameを配列に追加
              });
            }
          });
        }
      });

      setUserdefAttacks((prevUserdefAttacks) => ({
        ...prevUserdefAttacks,
        [editInfo.my_character]: userdefAttackNameArr,
      }));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{"集計"}</title>
        <meta
          name="description"
          content={
            "スマブラSP(Super Smash Bros Ultimate)におけるキャラクターの振った技の回数の集計を行います。PC版ではキーボードでの入力補助機能もありまうのでご活用ください。"
          }
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8034328441383028"
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: { xs: 14, sm: 20 },
          marginTop: "-50px",
        }}
        id="count-info"
      >
        {isLoading && <BackDrop />}
        <Box
          sx={{
            my: 4,
            textAlign: "center",
            padding: "16px",
            borderRadius: "8px",
          }}
        >
          {errorMessage && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              severity="success"
              sx={{ width: "100%", mb: 2, marginTop: 5 }}
            >
              {successMessage}
            </Alert>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <FormControl fullWidth margin="normal" sx={{ maxWidth: "300px" }}>
              <InputLabel id="character-select-label">
                集計対象のキャラクター選択
              </InputLabel>

              <Select
                labelId="character-select-label"
                label="集計対象のキャラクター選択"
                value={myCharacter}
                onChange={(e) => handleCharacterChange(e.target.value)}
                disabled={editFlg}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {myCharacter && (
            <>
              <Box>
                <CountTypeToggleButtonGroup
                  myCharacter={myCharacter}
                  displayedCountType={displayedCountType}
                  handleCountTypeChange={handleCountTypeChange}
                  getSpecialAttacks={getSpecialAttacks}
                />
              </Box>
              <ToggleButtonGroup
                color="primary"
                value={displayedDamagePercentType}
                exclusive
                onChange={handleDamagePercentTypeChange}
                aria-label="Attack type"
                sx={{ mb: 2 }}
              >
                <ToggleButton key="low" value="low">
                  ～50%
                </ToggleButton>
                <ToggleButton key="middle" value="middle">
                  51%～100%
                </ToggleButton>
                <ToggleButton key="high" value="high">
                  101%～
                </ToggleButton>
              </ToggleButtonGroup>

              {displayedCountType === "userdef" && (
                <>
                  <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                    sx={{ marginBottom: 4 }}
                    justifyContent={"center"}
                  >
                    <TextField
                      label="新しい項目"
                      variant="outlined"
                      size="small"
                      value={newAttackName}
                      inputProps={{ maxLength: 100 }}
                      onChange={(e) => setNewAttackName(e.target.value)}
                      style={{ marginRight: 8 }}
                      helperText="※30個まで追加可能"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddUserdefAttack(newAttackName)}
                    >
                      項目を追加
                    </Button>
                  </Box>
                </>
              )}
              <Grid container spacing={2} justifyContent="center">
                {countItems.map((attack, _index) => (
                  <Grid item xs={6} sm={4} md={3} key={attack}>
                    <TextField
                      label={attack}
                      type="number"
                      value={
                        counts[myCharacter]?.[displayedCountType]?.[
                          displayedDamagePercentType
                        ][attack]
                      }
                      onChange={(event) => handleChange(event, attack)}
                      InputProps={{
                        inputProps: { max: 999 },
                      }}
                      variant="outlined"
                      fullWidth
                      sx={{
                        backgroundColor:
                          highlightedAttack === attack
                            ? alpha("#E8F0FE", 1.0)
                            : "white",
                      }}
                    />
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="center"
                      mt={1}
                    >
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#E8F0FE",
                          color: "#4285f4",
                        }}
                        onClick={() => handleIncrement(attack)}
                      >
                        +
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDecrement(attack)}
                        style={{
                          backgroundColor: "#FEEAE8",
                          color: "#F44242",
                        }}
                      >
                        -
                      </Button>
                    </Stack>
                    {displayedCountType === "userdef" && (
                      <Checkbox
                        checked={checkedItems[attack] || false}
                        onChange={() => handleCheckChange(attack)}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
              {displayedCountType === "userdef" && (
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteCheckedItems}
                    disabled={Object.values(checkedItems).every(
                      (value) => value === false
                    )}
                  >
                    チェックした項目を削除
                  </Button>
                </Box>
              )}

              <Grid item xs={12} sm={10} md={8}>
                <Box mt={10}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ textAlign: "left" }}
                  >
                    撃墜データ入力 (集計対象側が撃墜した際のデータ)
                  </Typography>
                  {burstData.map((data, index) => (
                    <Grid
                      key={index}
                      container
                      mt={1}
                      spacing={2}
                      alignItems="center"
                      style={{ marginBottom: "10px" }}
                    >
                      {/* 撃墜技（burstAttack） */}
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          fullWidth
                          select
                          label="撃墜技"
                          value={data.burstAttack}
                          onChange={(e) =>
                            handleBurstInputChange(
                              index,
                              "burstAttack",
                              e.target.value
                            )
                          }
                          SelectProps={{
                            MenuProps,
                          }}
                        >
                          {burstAttackOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      {/* 撃墜状況（burstCondition） */}
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          fullWidth
                          select
                          label="撃墜状況"
                          value={data.burstCondition}
                          onChange={(e) =>
                            handleBurstInputChange(
                              index,
                              "burstCondition",
                              e.target.value
                            )
                          }
                        >
                          {burstConditionOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      {/* 撃墜%（burstPercentage） */}
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          fullWidth
                          type="number"
                          label="撃墜%"
                          value={data.burstPercentage}
                          inputProps={{ max: 999 }}
                          onChange={(e) =>
                            handleBurstInputChange(
                              index,
                              "burstPercentage",
                              e.target.value
                            )
                          }
                        />
                      </Grid>

                      {/* コメント（comment） */}
                      <Grid item xs={12} sm={10} md={4}>
                        <TextField
                          fullWidth
                          label="コメント ※上限100文字"
                          value={data.burstcomment}
                          multiline
                          onChange={(e) =>
                            handleBurstInputChange(
                              index,
                              "burstcomment",
                              e.target.value
                            )
                          }
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>

                      {/* 削除ボタン */}
                      <Grid item xs={12} sm={2} md={1}>
                        <Button
                          variant="contained"
                          onClick={() => handleRemoveBurstRow(index)}
                          sx={{ mx: 2, minWidth: "100px", height: "50px" }}
                          color="error"
                          startIcon={<DeleteIcon />}
                        >
                          削除
                        </Button>
                      </Grid>
                    </Grid>
                  ))}

                  <IconButton
                    onClick={handleAddBurstRow}
                    color="primary"
                    aria-label="add"
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "#1976d2",
                      color: "white",
                    }} // 円形にし、背景色を指定
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Grid>

              <Grid item xs={12} sm={10} md={8}>
                <Box mt={10}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ textAlign: "left" }}
                  >
                    被撃墜データ入力 (集計対象側が撃墜された際のデータ)
                  </Typography>
                  <FormControl
                    fullWidth
                    margin="normal"
                    sx={{ maxWidth: "300px" }}
                  >
                    <InputLabel id="opponent-character-select-label">
                      相手のキャラクター
                    </InputLabel>
                    <Select
                      labelId="opponent-character-select-label"
                      label="相手のキャラクター *"
                      value={opponentCharacter}
                      onChange={(e) =>
                        setOpponentCharacter(e.target.value as string)
                      }
                      MenuProps={MenuProps}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {burstedData.map((data, index) => (
                    <Grid
                      key={index}
                      container
                      mt={1}
                      spacing={2}
                      alignItems="center"
                      style={{ marginBottom: "10px" }}
                    >
                      {/* 被撃墜技（burstedAttack） */}
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          fullWidth
                          select
                          label="被撃墜技"
                          value={data.burstedAttack}
                          onChange={(e) =>
                            handleBurstedInputChange(
                              index,
                              "burstedAttack",
                              e.target.value
                            )
                          }
                          SelectProps={{
                            MenuProps,
                          }}
                        >
                          {burstedAttackOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      {/* 被撃墜状況（burstCondition） */}
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          fullWidth
                          select
                          label="被撃墜状況"
                          value={data.burstedCondition}
                          onChange={(e) =>
                            handleBurstedInputChange(
                              index,
                              "burstedCondition",
                              e.target.value
                            )
                          }
                        >
                          {burstConditionOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      {/* 被撃墜%（burstedPercentage） */}
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          fullWidth
                          type="number"
                          label="被撃墜%"
                          value={data.burstedPercentage}
                          inputProps={{ max: 999 }}
                          onChange={(e) =>
                            handleBurstedInputChange(
                              index,
                              "burstedPercentage",
                              e.target.value
                            )
                          }
                        />
                      </Grid>

                      {/* コメント（comment） */}
                      <Grid item xs={12} sm={10} md={4}>
                        <TextField
                          fullWidth
                          label="コメント ※上限100文字"
                          value={data.burstedcomment}
                          multiline
                          onChange={(e) =>
                            handleBurstedInputChange(
                              index,
                              "burstedcomment",
                              e.target.value
                            )
                          }
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>

                      {/* 削除ボタン */}
                      <Grid item xs={12} sm={2} md={1}>
                        <Button
                          variant="contained"
                          onClick={() => handleRemoveBurstedRow(index)}
                          sx={{ mx: 2, minWidth: "100px", height: "50px" }}
                          color="error"
                          startIcon={<DeleteIcon />}
                        >
                          削除
                        </Button>
                      </Grid>
                    </Grid>
                  ))}

                  <IconButton
                    onClick={handleAddBurstedRow}
                    color="primary"
                    aria-label="add"
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "#1976d2",
                      color: "white",
                    }} // 円形にし、背景色を指定
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Grid>

              <Box mt={4}>
                <Button
                  variant="contained"
                  onClick={() => setShowChart(!showChart)}
                  sx={{ mx: 2, minWidth: "100px", height: "50px" }}
                  startIcon={<BarChartIcon />}
                >
                  {showChart ? "閉じる" : "グラフ表示"}
                </Button>
              </Box>

              {showChart && (
                <div style={{ height: "1200px" }}>
                  <Bar
                    data={chartData}
                    plugins={[ChartDataLabels]}
                    options={chartOptions}
                  />
                </div>
              )}

              <Box mt={4}>
                <Button
                  variant="contained"
                  onClick={handleClickOpen}
                  sx={{ mx: 2, minWidth: "100px", height: "50px" }}
                  startIcon={editFlg ? <UpdateIcon /> : <SaveIcon />}
                >
                  {editFlg ? "更新" : "登録"}
                </Button>
              </Box>
            </>
          )}
        </Box>
        <Dialog open={open} onClose={handleClose} scroll={"paper"}>
          <DialogTitle>
            {editFlg ? "集計結果を更新" : "集計結果を登録"}
          </DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            <Stack>
              <Typography component="span" sx={{ color: "#F44242" }}>
                *必須
              </Typography>
              <FormControl
                fullWidth
                required
                error={errors.opponentCharacter}
                margin="normal"
              >
                <InputLabel id="opponent-character-select-label">
                  相手のキャラクター
                </InputLabel>
                <Select
                  labelId="opponent-character-select-label"
                  label="相手のキャラクター *"
                  value={opponentCharacter}
                  onChange={(e) =>
                    setOpponentCharacter(e.target.value as string)
                  }
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.opponentCharacter && (
                  <FormHelperText>必須項目です</FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                required
                error={errors.level}
              >
                <InputLabel id="level-select-label">レベル帯</InputLabel>
                <Select
                  labelId="level-select-label"
                  label="レベル帯 *"
                  value={level}
                  onChange={(e) => setLevel(e.target.value as string)}
                >
                  {levelItems.map((level) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
                {errors.level && <FormHelperText>必須項目です</FormHelperText>}
              </FormControl>

              <FormControl
                fullWidth
                margin="normal"
                required
                error={errors.matchType}
              >
                <InputLabel id="match-type-select-label">対戦区分</InputLabel>
                <Select
                  labelId="match-type-select-label"
                  label="対戦区分 *"
                  value={matchType}
                  onChange={(e) => setMatchType(e.target.value as string)}
                >
                  {gameDivItems.map((matchType) => (
                    <MenuItem key={matchType} value={matchType}>
                      {matchType}
                    </MenuItem>
                  ))}
                </Select>
                {errors.matchType && (
                  <FormHelperText>必須項目です</FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                required
                error={errors.date}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TextField
                    fullWidth
                    label="対戦日 *"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />

                  {errors.date && <FormHelperText>必須項目です</FormHelperText>}
                </LocalizationProvider>
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                required
                error={errors.stage}
              >
                <InputLabel id="stage-type-select-label">ステージ</InputLabel>
                <Select
                  labelId="stage-type-select-label"
                  label="ステージ *"
                  value={stage}
                  onChange={(e) => setStage(e.target.value as string)}
                >
                  {stageItems.map((stage) => (
                    <MenuItem key={stage} value={stage}>
                      {stage}
                    </MenuItem>
                  ))}
                </Select>
                {errors.stage && <FormHelperText>必須項目です</FormHelperText>}
              </FormControl>
              <Box>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ fontSize: "0.875rem" }}
                  mt={2}
                >
                  試合時間
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      required
                      error={errors.matchTime}
                    >
                      <InputLabel id="minutes-label">分</InputLabel>
                      <Select
                        labelId="minutes-label"
                        value={matchTime.minutes}
                        onChange={(e: SelectChangeEvent<string>) =>
                          setMatchTime((prevTime) => ({
                            ...prevTime,
                            minutes: e.target.value,
                          }))
                        }
                        label="分"
                      >
                        {minutesOptions.map((minute: number) => (
                          <MenuItem key={minute} value={minute}>
                            {minute} 分
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.matchTime && (
                        <FormHelperText>必須項目です</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      required
                      error={errors.matchTime}
                    >
                      <InputLabel id="seconds-label">秒</InputLabel>
                      <Select
                        labelId="seconds-label"
                        value={matchTime.seconds}
                        onChange={(e: SelectChangeEvent<string>) =>
                          setMatchTime((prevTime) => ({
                            ...prevTime,
                            seconds: e.target.value,
                          }))
                        }
                        label="秒"
                      >
                        {secondsOptions.map((second: number) => (
                          <MenuItem key={second} value={second}>
                            {second} 秒
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.matchTime && (
                        <FormHelperText>必須項目です</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <FormControl
                component="fieldset"
                margin="normal"
                required
                error={errors.battleType}
              >
                <RadioGroup
                  row
                  value={battleType}
                  onChange={(e) => setBattleType(e.target.value)}
                >
                  <FormControlLabel
                    value="オンライン"
                    control={<Radio />}
                    label="オンライン*"
                  />
                  <FormControlLabel
                    value="オフライン"
                    control={<Radio />}
                    label="オフライン*"
                  />
                  {errors.battleType && (
                    <FormHelperText>必須項目です</FormHelperText>
                  )}
                </RadioGroup>
              </FormControl>
              <FormControl
                component="fieldset"
                margin="normal"
                required
                error={errors.battleType}
              >
                <RadioGroup
                  row
                  value={outcome}
                  onChange={(e) => setOutcome(e.target.value)}
                >
                  <FormControlLabel
                    value="勝ち"
                    control={<Radio />}
                    label="勝ち*"
                  />
                  <FormControlLabel
                    value="負け"
                    control={<Radio />}
                    label="負け*"
                  />
                  <FormHelperText>集計対象の勝敗</FormHelperText>
                  {errors.outcome && (
                    <FormHelperText>必須項目です</FormHelperText>
                  )}
                </RadioGroup>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPublic}
                    onChange={(e) => setIsPublic(e.target.checked)}
                    color="primary"
                  />
                }
                label="データを公開する"
              />
              <TextField
                label="コメント"
                value={comments}
                onChange={(event) => setComments(event.target.value)}
                inputProps={{ maxLength: 100 }}
                fullWidth
                multiline
                sx={{ marginTop: 3 }}
                helperText="※上限100文字"
              />
              <TextField
                label="対戦動画のURL"
                value={videoUrl}
                onChange={(event) => setVideoUrl(event.target.value)}
                inputProps={{ maxLength: 1000 }}
                fullWidth
                sx={{ marginTop: 3 }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              キャンセル
            </Button>
            {editFlg ? (
              <Button onClick={handleUpdateWithValidation} color="primary">
                更新
              </Button>
            ) : (
              <Button onClick={handleSubmitWithValidation} color="primary">
                登録
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default Count;
