import { Container } from "@mui/material";
import React from "react";
import { Section } from "./common/TextStyle";
import { Helmet } from "react-helmet-async";

const TermUse: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{"利用規約"}</title>
        <meta
          name="description"
          content={
            "当サイトはスマブラSPにおけるキャラクターの振った技の回数の集計、集計データの登録、集計データの参照機能を提供します。"
          }
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: { xs: 14, sm: 20 },
          marginTop: "-50px",
        }}
      >
        <Section title="利用規約">
          当サイトを利用する前に、利用規約をよくお読みいただき、同意いただいた上でご利用ください。
          <br />
          当サイトはスマブラSPにおけるキャラクターの振った技の回数の集計、集計データの登録、集計データの
          <br />
          参照機能を提供します。
          <br />
          当サイトに掲載されるデータは各個人で集計されたものであるため、集計方法に必ずばらつきが生じます。
          <br />
          そのため、当サイトのデータは参考値程度に捉えていただけますと幸いです。
          <br />
          当サイトでは集計データに対してコメントや対戦動画URLをつける機能があります。
          <br />
          誹謗中傷などの不適切なコメント、対戦動画ではないURLが見られた場合は対象のアカウントを削除することがありますのでご理解ください。
          <br />
          みなさんでデータを集計して参照しあい、より良いスマブラライフを送っていただければ幸いです。
        </Section>
        <Section title="プライバシーポリシー">
          当サイトではアカウントの認証にCookie（クッキー）を利用しています。
          <br />
          また、Cookieで取得した情報を利用して、Webサイトの利用状況を分析し、パフォーマンスの改善やサービス向上を目的として使用することがあります。
          <br />
          <br />
          当サイトでは、第三者配信の広告サービス（Googleアドセンス）を利用しており、
          <br />
          ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しています。
          <br />
          クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、
          <br />
          お客様個人を特定できるものではありません。
          <br />
          Googleアドセンスに関する詳細は「広告 –ポリシーと規約 –
          Google」をご確認ください。
          <br />
          <br />
          ブラウザの設定を変更することによりクッキーを無効にすることが可能です。
          <br />
          ただし、クッキーを無効にした場合、アカウントをお持ちの方は認証を行えずログインが行えません。
        </Section>
        <Section title="免責事項">
          当サイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。
          <br />
          掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
          <br />
          第三者のプレイヤーネームの使用によって生じたトラブルや損害について一切の責任を負いません。
          <br />
        </Section>
      </Container>
    </>
  );
};

export default TermUse;
