import {
  CountData,
  CountType,
  Stats,
  BurstData,
  BurstedData,
  initialCountData,
  PercentType,
} from "./constants";

// 平均値を計算する
// 平均値計算時にグラフ表示用にデータも補正する
// 複数グラフを表示した際に軸の値を揃えるため、データの最大値を取得し空データとして加える
export const calculateAverages = (data: CountData[]): CountData => {
  const result: CountData = JSON.parse(JSON.stringify(initialCountData));

  // 合計値を計算
  data.forEach((item) => {
    (Object.keys(result) as CountType[]).forEach((key) => {
      if (item[key]) {
        (Object.keys(item[key]) as PercentType[]).forEach((subKey) => {
          // countName のキーを取得
          Object.keys(item[key][subKey]).forEach((countName) => {
            if (!result[key][subKey]) {
              result[key][subKey] = {};
            }
            if (!result[key][subKey][countName]) {
              result[key][subKey][countName] = 0;
            }
            result[key][subKey][countName] += item[key][subKey][countName];
          });
        });
      }
    });
  });

  // 平均値を計算
  (Object.keys(result) as CountType[]).forEach((key) => {
    (Object.keys(result[key]) as PercentType[]).forEach((subKey) => {
      // countName のキーを取得
      Object.keys(result[key][subKey]).forEach((countName) => {
        // 小数点切り上げ
        result[key][subKey][countName] = Math.ceil(
          result[key][subKey][countName] / data.length
        );
      });
    });
  });

  return result;
};

// 単位時間(1分)あたりのデータに変換
export const calculateRatePerUnitTime = (
  data: CountData[],
  time: { minutes: string; seconds: string }[]
): CountData[] => {
  // 分単位に変換して結果を割る処理をここで行う
  const convertedData = data.map((item, index) => {
    const totalMinutes =
      parseInt(time[index].minutes, 10) +
      parseInt(time[index].seconds, 10) / 60;

    // 変換された新しいデータを返す
    const newItem: CountData = JSON.parse(JSON.stringify(initialCountData));

    (Object.keys(item) as CountType[]).forEach((key) => {
      if (item[key]) {
        (Object.keys(item[key]) as PercentType[]).forEach((subKey) => {
          // 各攻撃名に対して分単位で割る
          Object.keys(item[key][subKey]).forEach((countName) => {
            newItem[key][subKey][countName] = Math.ceil(
              item[key][subKey][countName] / totalMinutes
            );
          });
        });
      }
    });

    return newItem;
  });

  return convertedData;
};

// グラフ表示用にデータを補正する
// 複数グラフを表示した際に軸の値を揃えるため、データの最大値を取得する
export const getMaxvalueObj = (
  data: CountData[]
): {
  [key: string]: number;
} => {
  const result: CountData = JSON.parse(JSON.stringify(initialCountData));

  // 各キーの初期値を0に設定する
  let maxValueObj: { [key: string]: number } = {};
  (Object.keys(result) as CountType[]).forEach((key) => {
    maxValueObj[key] = 0; // 初期値の設定
  });

  // 最大値を取得
  data.forEach((item) => {
    (Object.keys(result) as CountType[]).forEach((key) => {
      if (item[key]) {
        (Object.keys(item[key]) as PercentType[]).forEach((subKey) => {
          Object.keys(item[key][subKey]).forEach((countName) => {
            // なぜか空文字のキーデータ(全データの最大値)が消えず、のこなったまま処理されるので除外する
            if (countName !== "") {
              if (item[key][subKey][countName] > maxValueObj[key]) {
                maxValueObj[key] = item[key][subKey][countName];
              }
            }
          });
        });
      }
    });
  });

  return maxValueObj;
};

// 共通の統計計算関数
export const calculateStats = (data: number[]): Stats => {
  const total = data.reduce((acc, val) => acc + val, 0);
  const average = (total / data.length).toFixed(1);
  const min = Math.min(...data).toFixed(1);
  const max = Math.max(...data).toFixed(1);

  const sortedData = [...data].sort((a, b) => a - b);
  const mid = Math.floor(sortedData.length / 2);
  const median = (
    sortedData.length % 2 !== 0
      ? sortedData[mid]
      : (sortedData[mid - 1] + sortedData[mid]) / 2
  ).toFixed(1);

  return { average, min, max, median };
};

// BurstDataから統計を計算する関数
export const calculateBurstStats = (burstData: BurstData[][]): Stats => {
  const percentages = burstData
    .flat()
    .map((burst) => Number(burst.burstPercentage));
  return calculateStats(percentages);
};

// BurstedDataから統計を計算する関数
export const calculateBurstedStats = (burstedData: BurstedData[][]): Stats => {
  const percentages = burstedData
    .flat()
    .map((burst) => Number(burst.burstedPercentage));
  return calculateStats(percentages);
};

export const countOccurrences = (
  dataArray: BurstData[][] | BurstedData[][],
  key: string
) =>
  dataArray.flat().reduce((acc, item: any) => {
    acc[item[key]] = (acc[item[key]] || 0) + 1;
    return acc;
  }, {} as { [key: string]: number });
