import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import SearchPage from "./pages/SearchPage";
import CountPage from "./pages/CountPage";
import SignUpPage from "./pages/SignUpPage";
import SignInPage from "./pages/SignInPage";
import DeleteAccountPage from "./pages/DeleteAccountPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from "./AuthContext";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import MydataEditPage from "./pages/MydataEditPage";
import TermUsePage from "./pages/TermUsePage";
import ActivatePage from "./pages/ActivatePage";
import PasswordResetPage from "./pages/PasswordResetPage";
import ReactGA from "react-ga4";
import { HelmetProvider, Helmet } from "react-helmet-async";

function App() {
  ReactGA.initialize("G-DJ6RDRWBY1");

  return (
    <HelmetProvider>
      <AuthProvider>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/count" element={<CountPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/deleteAccount" element={<DeleteAccountPage />} />
            <Route path="/change-password" element={<ChangePasswordPage />} />
            <Route path="/forget-password" element={<ForgetPasswordPage />} />
            <Route path="/mydata-edit" element={<MydataEditPage />} />
            <Route path="/termuse" element={<TermUsePage />} />
            <Route
              path="/activate/:uidb64/:token/"
              element={<ActivatePage />}
            />
            <Route
              path="/reset-password/:uidb64/:token/"
              element={<PasswordResetPage />}
            />
          </Routes>
        </Router>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
