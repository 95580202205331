import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { CountType } from "../../common/constants";

interface CountTypeToggleButtonGroupProps {
  myCharacter: string;
  displayedCountType: CountType;
  handleCountTypeChange: (
    event: React.MouseEvent<HTMLElement>,
    newType: CountType | null
  ) => void;
  getSpecialAttacks: (myCharacter: string) => string[];
}

const CountTypeToggleButtonGroup: React.FC<CountTypeToggleButtonGroupProps> = ({
  myCharacter,
  displayedCountType,
  handleCountTypeChange,
  getSpecialAttacks,
}) => {
  let attackButtons: JSX.Element[] = [];

  if (myCharacter === "ホムラ/ヒカリ") {
    attackButtons = [
      <ToggleButton key="normal1" value="normal1">
        通常技(ホムラ)
      </ToggleButton>,
      <ToggleButton key="normal2" value="normal2">
        通常技(ヒカリ)
      </ToggleButton>,
    ];
  } else if (myCharacter === "ジョーカー") {
    attackButtons = [
      <ToggleButton key="normal1" value="normal1">
        通常技
      </ToggleButton>,
      <ToggleButton key="normal2" value="normal2">
        通常技(アルセーヌ)
      </ToggleButton>,
    ];
  } else if (myCharacter === "ポケモントレーナー") {
    attackButtons = [
      <ToggleButton key="normal1" value="normal1">
        通常技(ゼニガメ)
      </ToggleButton>,
      <ToggleButton key="normal2" value="normal2">
        通常技(フシギソウ)
      </ToggleButton>,
      <ToggleButton key="normal3" value="normal3">
        通常技(リザードン)
      </ToggleButton>,
    ];
  } else if (myCharacter === "ミェンミェン") {
    attackButtons = [
      <ToggleButton key="normal1" value="normal1">
        通常技(ホットリング)
      </ToggleButton>,
      <ToggleButton key="normal2" value="normal2">
        通常技(メガボルト)
      </ToggleButton>,
      <ToggleButton key="normal3" value="normal3">
        通常技(ドラゴン)
      </ToggleButton>,
    ];
  } else {
    attackButtons = [
      <ToggleButton key="normal" value="normal">
        通常技
      </ToggleButton>,
    ];

    if (getSpecialAttacks(myCharacter).length > 0) {
      attackButtons.push(
        <ToggleButton key="special" value="special">
          特殊技
        </ToggleButton>
      );
    }
  }

  attackButtons.push(
    <ToggleButton key="other" value="other">
      その他
    </ToggleButton>
  );

  attackButtons.push(
    <ToggleButton key="userdef" value="userdef">
      ユーザー定義
    </ToggleButton>
  );

  return (
    <ToggleButtonGroup
      color="primary"
      value={displayedCountType}
      exclusive
      onChange={handleCountTypeChange}
      aria-label="Attack type"
      sx={{ mb: 2 }}
    >
      {attackButtons}
    </ToggleButtonGroup>
  );
};

export default CountTypeToggleButtonGroup;
