import { Container } from "@mui/material";
import React from "react";
import { Section } from "./common/TextStyle";

const CountNote: React.FC = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        pb: { xs: 2, sm: 4 },
      }}
    >
      <Section title="集計方法">
        <ol>
          <li>集計対象のキャラクターを選択します。</li>
          <li>
            キャラクターに応じた入力欄が表示されるのでそちらに振った技の回数を入力します。
            <br />
            特殊技のタブがあるキャラクターはどの技がそれに該当するか確認してください。
            <br />
            集計したい項目がない場合はユーザー定義のタブからユーザー独自の項目を設定して集計ができます。
            <br />
            データのばらつきを抑えるために集計時は下記のガイドラインになるべく従うようお願いいたします。
            <br />
            ガイドラインで考慮されていない箇所も多々ありますのでそちらに関しては個人の判断に委ねます。
            <br />
            PCなどで閲覧されていてキーボードがある場合は通常技とその他の項目でキーボード入力機能をサポートしていますので是非ご利用ください。
            <br />
            <br />
            キーボード入力機能(通常技)
            ※例:→Aはキーボードの右方向キーを押下後、1秒以内にAボタンを押下(同時押しではありません)
            <br />
            <br />
            A:弱、←Aまたは→A:横強、↑A:上強、↓A:下強
            <br />
            Q:DA
            <br />
            ←Sまたは→S:横スマ、↑S:上スマ、↓S:下スマ
            <br />
            D:NB、←Dまたは→D:横B、↑D:上B、↓D:下B
            <br />
            W:空N、→W:空前、←W:空後、↑W:空上、↓W:空下
            <br />
            X:掴み(投げなし)、→X:前投げ、←X:後ろ投げ、↑X:上投げ、↓X:下投げ
            <br />
            Tab:通常技が複数あるキャラに対して通常技のタブを切り替える
            ※ジョーカー、ポケモントレーナー、ホムラヒカリなど
            <br />
            Space:%帯のタブを切り替える
            <br />
            <br />
            キーボード入力機能(その他)
            ※例:→Aはキーボードの右方向キーを押下後、1秒以内にAボタンを押下(同時押しではありません)
            <br />
            <br />
            A:ガード
            <br />
            Q:攻撃上がり
            <br />
            S:地上その場回避、←Sまたは→S:地上移動回避
            <br />
            D:その場上がり、←Dまたは→D:転がり上がり、↑D:ジャンプ上がり、↓D:崖はなし
            <br />
            W:空中N回避、←Wまたは→W:空中移動回避
            <br />
          </li>
          <br />
          <li>
            入力が完了したら登録ボタンを押下し、登録を行ってください。登録したデータは全ユーザーに公開されます。
            <br />
            <span style={{ color: "#F44242" }}>
              データの登録にはアカウント登録が必要となります。
            </span>
            <br />
            登録時の項目の対戦区分(大会規模)については各々の主観に委ねます。
            <br />
            登録時の項目のレベル帯についてはクマメイト様、スマメイト様のレーティングを基準としています。
            <br />
            <a href="https://kumamate.net/vip/">クマメイト様</a>
            <br />
            <a href="https://smashmate.net/">スマメイト様</a>
            <br />
          </li>
          <br />
          ※グラフ表示ボタンから入力中のデータをグラフ表示できます。
        </ol>
      </Section>
      <div id="guideline">
        <Section title="集計ガイドライン">
          <ol>
            <li>
              1試合7分3ストック、1on1、アイテムなしの試合を対象としてください。
            </li>
            <li>
              連続で出せる技は出し始めから終わりまでを1回としてカウントしてください。
              <br />
              例：弱1、弱2は1回と数える
              <br />
            </li>
            <li>
              キャラクターの状態によって技が変化するものは変化前の技を通常技のタブ、変化後は特殊技のタブに入力してください。
              <br />
              例:リトル・マックのKO、クラウドの画竜点晴など
              <br />
            </li>
            <li>
              生成系の技は生成した回数をカウントしてください。
              <br />
              例:リンクの爆弾、スネークの手榴弾など
              <br />
            </li>
            <li>
              溜めてから放つタイプの技は放った回数をカウントしてください。溜める行為はカウントしないでください。
              <br />
              例:サムスのチャージショット、ドンキーのジャイアントパンチなど
              <br />
            </li>
            <li>
              バースト後などの相手がいない状況で振った技(無意味な技)はカウントしないてください。
            </li>
          </ol>
          <br />
          ※管理者も各キャラクターを熟知しているわけではなく、手探りの状態なので何か良い集計ルールがあればお問い合わせからご意見ください
        </Section>
      </div>
    </Container>
  );
};

export default CountNote;
