type CharacterName = string;

export const names: CharacterName[] = [
  "マリオ",
  "ドンキーコング",
  "リンク",
  "サムス",
  "ダークサムス",
  "ヨッシー",
  "カービィ",
  "フォックス",
  "ピカチュウ",
  "ルイージ",
  "ネス",
  "キャプテンファルコン",
  "プリン",
  "ピーチ",
  "デイジー",
  "クッパ",
  "アイスクライマー",
  "シーク",
  "ゼルダ",
  "ドクターマリオ",
  "ピチュー",
  "ファルコ",
  "マルス",
  "ルキナ",
  "こどもリンク",
  "ガノンドロフ",
  "ミュウツー",
  "ロイ",
  "クロム",
  "Mr.ゲーム＆ウォッチ",
  "メタナイト",
  "ピット",
  "ブラックピット",
  "ゼロスーツサムス",
  "ワリオ",
  "スネーク",
  "アイク",
  "ポケモントレーナー",
  "ディディーコング",
  "リュカ",
  "ソニック",
  "デデデ",
  "ピクミン＆オリマー",
  "ルカリオ",
  "ロボット",
  "トゥーンリンク",
  "ウルフ",
  "むらびと",
  "ロックマン",
  "Wii Fit トレーナー",
  "ロゼッタ＆チコ",
  "リトル・マック",
  "ゲッコウガ",
  "Miiファイター（格闘）",
  "Miiファイター（剣術）",
  "Miiファイター（射撃）",
  "パルテナ",
  "パックマン",
  "ルフレ",
  "シュルク",
  "クッパJr.",
  "ダックハント",
  "リュウ",
  "ケン",
  "クラウド",
  "カムイ",
  "ベヨネッタ",
  "インクリング",
  "リドリー",
  "シモン",
  "リヒター",
  "キングクルール",
  "しずえ",
  "ガオガエン",
  "パックンフラワー",
  "ジョーカー",
  "勇者",
  "バンジョー&カズーイ",
  "テリー・ボガード",
  "ベレト",
  "ミェンミェン",
  "スティーブ",
  "セフィロス",
  "ホムラ/ヒカリ",
  "カズヤ",
  "ソラ",
];

export const normalAttacks = [
  "弱",
  "横強",
  "上強",
  "下強",
  "DA",
  "横スマ",
  "上スマ",
  "下スマ",
  "NB",
  "横B",
  "上B",
  "下B",
  "空N",
  "空前",
  "空後",
  "空上",
  "空下",
  "掴み(投げなし)",
  "前投げ",
  "後ろ投げ",
  "上投げ",
  "下投げ",
];

export const otherCounts = [
  "ガード",
  "地上その場回避",
  "地上移動回避",
  "空中N回避",
  "空中移動回避",
  "その場上がり",
  "転がり上がり",
  "ジャンプ上がり",
  "攻撃上がり",
  "崖はなし",
];

export const specialAttacksMap: Record<CharacterName, string[]> = {
  "リトル・マック": ["K.Oアッパーカット"],
  カービィ: ["コピー必殺技"],
  クラウド: [
    "リミット破晄撃",
    "リミット凶切り",
    "リミットクライムハザード",
    "画竜点晴",
  ],
  "Miiファイター（格闘）": [
    "鉄球投げ",
    "閃光マッハパンチ",
    "爆裂サイドキック",
    "瞬発百裂キック",
    "燃焼ドロップキック",
    "スープレックス",
    "天地キック",
    "昇天スピンキック",
    "突き上げアッパーカット",
    "くい打ちヘッドバッド",
    "反転キック",
    "カウンター投げ",
  ],
  "Miiファイター（剣術）": [
    "トルネードショット",
    "光手裏剣",
    "ラピッドスラッシュ",
    "変則急襲斬り",
    "疾風突き",
    "チャクラム",
    "ロケット下突き",
    "ソードダッシュ",
    "回転斬り",
    "カウンター",
    "リバーススラッシュ",
    "ジェットスタッブ",
  ],
  "Miiファイター（射撃）": [
    "ガンナーチャージ",
    "ラピッドショット",
    "グレネードランチャー",
    "フレイムピラー",
    "ステルスボム",
    "ミサイル",
    "ボトムシュート",
    "キャノンジャンプキック",
    "アームロケット",
    "リフレクター",
    "クラウンドボム",
    "アブソーバー",
  ],
  パックマン: [
    "チェリー",
    "ストロベリー",
    "オレンジ",
    "アップル",
    "メロン",
    "ボス・ギャラクシアン",
    "ベル",
    "カギ",
  ],
  ルフレ: ["サンダー", "エルサンダー", "ギガサンダー", "トロン"],
  シュルク: [
    "モナドアーツ翔",
    "モナドアーツ疾",
    "モナドアーツ盾",
    "モナドアーツ斬",
    "モナドアーツ撃",
  ],
  リュウ: ["波動拳", "灼熱波動拳", "竜巻旋風脚", "昇龍拳"],
  ケン: [
    "波動拳",
    "鉈落とし蹴り",
    "竜巻旋風脚",
    "昇龍拳",
    "大回し蹴り",
    "稲妻かかと割り",
  ],
  勇者: [
    "メラ",
    "メラミ",
    "メラゾーマ",
    "デイン",
    "ライデイン",
    "ギガデイン",
    "バギ",
    "バギマ",
    "バギクロス",
    "イオ",
    "イオナズン",
    "ギラ",
    "ベギラマ",
    "ザキ",
    "ザラキ",
    "メガンテ",
    "マダンテ",
    "ラリホー",
    "ホイミ",
    "バイキルト",
    "ピオリム",
    "マホカンタ",
    "アストロン",
    "ルーラ",
    "パルプンテ",
    "かえん斬り",
    "マヒャド斬り",
    "まじん斬り",
    "メタル斬り",
    "ためる",
  ],
  "テリー・ボガード": ["パワーゲイザー", "バスターウルフ"],
  セフィロス: ["フレア", "メガフレア", "ギガフレア", "一閃", "八刀一閃"],
  カズヤ: [
    "レイジドライブ",
    "閃光烈拳",
    "左踵落とし",
    "ダブルアッパー",
    "螺旋幻魔脚",
    "鬼蹴り",
    "踵切り",
    "捻り裏拳",
    "腿砕き",
    "刃閃風",
    "ジャンプサイドキック",
    "螺旋岩砕蹴",
    "シットジャブ",
    "シットスピンキック",
    "魔神拳",
    "風神拳",
    "最速風神拳",
    "雷神拳",
    "奈落旋風",
    "地獄門",
  ],
};

export const twoNormalAttacksChara = ["ジョーカー", "ホムラ/ヒカリ"];
export const threeNormalAttacksChara = ["ミェンミェン", "ポケモントレーナー"];

export const levelItems = [
  "スマメイトR2000以上",
  "宇宙最強",
  "トップオブザ魔境",
  "魔境到達",
  "VIP到達",
  "未VIP",
];

export const gameDivItems = [
  "大規模大会",
  "中規模大会",
  "小規模大会",
  "スマメイト",
  "タミスマ",
  "VIPマッチ",
  "フリー対戦",
];

export const battleItems = ["オンライン", "オフライン"];

export const outcomeItems = ["勝ち", "負け"];

export const burstConditionOptions = [
  "復帰阻止",
  "着地狩り",
  "崖",
  "暴れ",
  "差し込み",
  "差し返し",
  "置き",
  "シールドブレイク",
  "その他",
];

export const stageItems = [
  "戦場(戦場化)",
  "終点(終点化)",
  "小戦場",
  "ポケモンスタジアム2",
  "すま村",
  "村と街",
  "ホロウバスティオン",
  "北の大空洞",
  "カロスポケモンリーグ",
  "ヨッシーストーリー",
  "ライラットクルーズ",
  "その他",
];

// 0分～7分と0秒～59秒の選択肢を作成
export const minutesOptions = Array.from({ length: 7 }, (_, i) => i);
export const secondsOptions = Array.from({ length: 60 }, (_, i) => i);

export const characterImages: { [key: string]: string } = {
  マリオ: "charaIcon\\chara_0_mario_00.png",
  ドンキーコング: "charaIcon\\chara_0_donkey_00.png",
  リンク: "charaIcon\\chara_0_link_00.png",
  サムス: "charaIcon\\chara_0_samus_00.png",
  ダークサムス: "charaIcon\\chara_0_samusd_00.png",
  ヨッシー: "charaIcon\\chara_0_yoshi_00.png",
  カービィ: "charaIcon\\chara_0_kirby_00.png",
  フォックス: "charaIcon\\chara_0_fox_00.png",
  ピカチュウ: "charaIcon\\chara_0_pikachu_00.png",
  ルイージ: "charaIcon\\chara_0_luigi_00.png",
  ネス: "charaIcon\\chara_0_ness_00.png",
  キャプテンファルコン: "charaIcon\\chara_0_captain_00.png",
  プリン: "charaIcon\\chara_0_purin_00.png",
  ピーチ: "charaIcon\\chara_0_peach_00.png",
  デイジー: "charaIcon\\chara_0_daisy_00.png",
  クッパ: "charaIcon\\chara_0_koopa_00.png",
  アイスクライマー: "charaIcon\\chara_0_ice_climber_00.png",
  シーク: "charaIcon\\chara_0_sheik_00.png",
  ゼルダ: "charaIcon\\chara_0_zelda_00.png",
  ドクターマリオ: "charaIcon\\chara_0_mariod_00.png",
  ピチュー: "charaIcon\\chara_0_pichu_00.png",
  ファルコ: "charaIcon\\chara_0_falco_00.png",
  マルス: "charaIcon\\chara_0_marth_00.png",
  ルキナ: "charaIcon\\chara_0_lucina_00.png",
  こどもリンク: "charaIcon\\chara_0_younglink_00.png",
  ガノンドロフ: "charaIcon\\chara_0_ganon_00.png",
  ミュウツー: "charaIcon\\chara_0_mewtwo_00.png",
  ロイ: "charaIcon\\chara_0_roy_00.png",
  クロム: "charaIcon\\chara_0_chrom_00.png",
  "Mr.ゲーム＆ウォッチ": "charaIcon\\chara_0_gamewatch_00.png",
  メタナイト: "charaIcon\\chara_0_metaknight_00.png",
  ピット: "charaIcon\\chara_0_pit_00.png",
  ブラックピット: "charaIcon\\chara_0_pitb_00.png",
  ゼロスーツサムス: "charaIcon\\chara_0_szerosuit_00.png",
  ワリオ: "charaIcon\\chara_0_wario_00.png",
  スネーク: "charaIcon\\chara_0_snake_00.png",
  アイク: "charaIcon\\chara_0_ike_00.png",
  ポケモントレーナー: "charaIcon\\chara_0_ptrainer_00.png",
  ディディーコング: "charaIcon\\chara_0_diddy_00.png",
  リュカ: "charaIcon\\chara_0_lucas_00.png",
  ソニック: "charaIcon\\chara_0_sonic_00.png",
  デデデ: "charaIcon\\chara_0_dedede_00.png",
  "ピクミン＆オリマー": "charaIcon\\chara_0_pikmin_00.png",
  ルカリオ: "charaIcon\\chara_0_lucario_00.png",
  ロボット: "charaIcon\\chara_0_robot_00.png",
  トゥーンリンク: "charaIcon\\chara_0_toonlink_00.png",
  ウルフ: "charaIcon\\chara_0_wolf_00.png",
  むらびと: "charaIcon\\chara_0_murabito_00.png",
  ロックマン: "charaIcon\\chara_0_rockman_00.png",
  "Wii Fit トレーナー": "charaIcon\\chara_0_wiifit_00.png",
  "ロゼッタ＆チコ": "charaIcon\\chara_0_rosetta_00.png",
  "リトル・マック": "charaIcon\\chara_0_littlemac_00.png",
  ゲッコウガ: "charaIcon\\chara_0_gekkouga_00.png",
  "Miiファイター（格闘）": "charaIcon\\chara_0_miifighter_00.png",
  "Miiファイター（剣術）": "charaIcon\\chara_0_miiswordsman_00.png",
  "Miiファイター（射撃）": "charaIcon\\chara_0_miigunner_00.png",
  パルテナ: "charaIcon\\chara_0_palutena_00.png",
  パックマン: "charaIcon\\chara_0_pacman_00.png",
  ルフレ: "charaIcon\\chara_0_reflet_00.png",
  シュルク: "charaIcon\\chara_0_shulk_00.png",
  "クッパJr.": "charaIcon\\chara_0_koopajr_00.png",
  ダックハント: "charaIcon\\chara_0_duckhunt_00.png",
  リュウ: "charaIcon\\chara_0_ryu_00.png",
  ケン: "charaIcon\\chara_0_ken_00.png",
  クラウド: "charaIcon\\chara_0_cloud_00.png",
  カムイ: "charaIcon\\chara_0_kamui_00.png",
  ベヨネッタ: "charaIcon\\chara_0_bayonetta_00.png",
  インクリング: "charaIcon\\chara_0_inkling_00.png",
  リドリー: "charaIcon\\chara_0_ridley_00.png",
  シモン: "charaIcon\\chara_0_richter_00.png",
  リヒター: "charaIcon\\chara_0_simon_00.png",
  キングクルール: "charaIcon\\chara_0_krool_00.png",
  しずえ: "charaIcon\\chara_0_shizue_00.png",
  ガオガエン: "charaIcon\\chara_0_gaogaen_00.png",
  パックンフラワー: "charaIcon\\chara_0_packun_00.png",
  ジョーカー: "charaIcon\\chara_0_jack_00.png",
  勇者: "charaIcon\\chara_0_brave_00.png",
  "バンジョー&カズーイ": "charaIcon\\chara_0_buddy_00.png",
  "テリー・ボガード": "charaIcon\\chara_0_dolly_00.png",
  ベレト: "charaIcon\\chara_0_master_00.png",
  ミェンミェン: "charaIcon\\chara_0_tantan_00.png",
  スティーブ: "charaIcon\\chara_0_pickel_00.png",
  セフィロス: "charaIcon\\chara_0_edge_00.png",
  "ホムラ/ヒカリ": "charaIcon\\chara_7_eflame_first_00.png",
  カズヤ: "charaIcon\\chara_0_demon_00.png",
  ソラ: "charaIcon\\chara_0_trail_00.png",
};

export type CountType =
  | "normal"
  | "special"
  | "normal1"
  | "normal2"
  | "normal3"
  | "other"
  | "userdef";

export type PercentType = "low" | "middle" | "high";

export const PercentItem: PercentType[] = ["low", "middle", "high"];
export const CountItem = [
  "normal",
  "special",
  "normal1",
  "normal2",
  "normal3",
  "other",
  "userdef",
];

export type CountData = {
  [key in CountType]: {
    [key in PercentType]: {
      [countName: string]: number;
    };
  };
};

export const initialCountData: CountData = {
  normal: {
    low: {},
    middle: {},
    high: {},
  },
  special: {
    low: {},
    middle: {},
    high: {},
  },
  normal1: {
    low: {},
    middle: {},
    high: {},
  },
  normal2: {
    low: {},
    middle: {},
    high: {},
  },
  normal3: {
    low: {},
    middle: {},
    high: {},
  },
  other: {
    low: {},
    middle: {},
    high: {},
  },
  userdef: {
    low: {},
    middle: {},
    high: {},
  },
};

export type BurstData = {
  burstAttack: string;
  burstCondition: string;
  burstPercentage: string;
  burstcomment: string;
};

export type BurstedData = {
  burstedAttack: string;
  burstedCondition: string;
  burstedPercentage: string;
  burstedcomment: string;
};

export type Stats = {
  average: string;
  min: string;
  max: string;
  median: string;
};

export type SearchResult = {
  id: string;
  my_character: string;
  counts: CountData;
  opponent_character: string;
  level: string;
  match_type: string;
  battle_date: string;
  battle_type: string;
  outcome: string;
  stage: string;
  is_public: boolean;
  video_url: string;
  comments: string;
  burst_data: BurstData[];
  bursted_data: BurstedData[];
  match_time: { minutes: string; seconds: string };
};
