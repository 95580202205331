import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TwitterIcon from "@mui/icons-material/X";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";

const logoStyle = {
  width: "140px",
  height: "auto",
};

export default function Footer() {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: { xs: 8, sm: 10 },
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pt: { xs: 2, sm: 4 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
          textAlign: "center",
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{
            color: "text.secondary",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              src={"Smash_Counter.svg"}
              style={logoStyle}
              alt="logo of sitemark"
              onClick={() => handleHomeClick()}
            />
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            mt={1}
            sx={{
              mb: { xs: 2, sm: 4 },
              fontSize: {
                xs: "0.8rem", // Small screen size
              },
            }}
          >
            大乱闘スマッシュブラザーズは、任天堂株式会社様の登録商標であり、当サイトは個人が運営する非公式のWebサービスです。
            <br />
            任天堂株式会社様、他関連企業様とは一切関係がありませんので、予めご了承ください。
          </Typography>
          <Typography variant="body2" color="text.secondary" mt={1}>
            ver2.0.0 2024/09/28
          </Typography>
          <Typography variant="body2" color="text.secondary" mt={1}>
            ©Smash Counter All rights reserved.
          </Typography>
        </Stack>
      </Box>
      <IconButton
        color="inherit"
        size="small"
        href="https://x.com/counter_ssbu"
        aria-label="X"
        sx={{ ml: "auto" }}
      >
        <TwitterIcon />
      </IconButton>
    </Container>
  );
}
