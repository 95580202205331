import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { PaletteMode } from "@mui/material";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import BackDrop from "./BackDrop";

const logoStyle = {
  width: "120px",
  height: "auto",
  cursor: "pointer",
  marginLeft: "20px",
  marginTop: "10px",
};

interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

function AppAppBar({ mode, toggleColorMode }: AppAppBarProps) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const { isAuthenticated, username, logoutUser } = authContext!;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const handleSignInClick = () => {
    navigate("/signin");
  };

  const handleDeleteAccountClick = () => {
    navigate("/deleteAccount");
  };

  const handleLogout = async () => {
    setIsLoading(true);
    await logoutUser();
    setIsLoading(false);
    navigate("/");
  };

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePasswordChangeClick = () => {
    navigate("/change-password");
  };

  const handleMydataEditClick = () => {
    navigate("/mydata-edit");
  };

  const handleTermUseClick = () => {
    navigate("/termuse");
  };

  useEffect(() => {
    const events = ["click", "mousemove", "keydown", "scroll", "touchstart"];

    if (isAuthenticated) {
      const resetTimer = () => {
        clearTimeout(logoutTimer);
        logoutTimer = setTimeout(async () => {
          await logoutUser();
          setErrorMessage(
            "一定時間操作がなかったため、ログアウトしました。再ログインしてください。"
          );
        }, 1000 * 60 * 60); // 1時間操作がない場合にログアウト
      };

      let logoutTimer = setTimeout(async () => {
        await logoutUser();
        setErrorMessage(
          "一定時間操作がなかったため、ログアウトしました。再ログインしてください。"
        );
      }, 1000 * 60 * 60); // 1時間操作がない場合にログアウト

      events.forEach((event) => window.addEventListener(event, resetTimer));

      return () => {
        events.forEach((event) =>
          window.removeEventListener(event, resetTimer)
        );
        clearTimeout(logoutTimer);
      };
    }
  }, []);

  return (
    <div>
      {errorMessage && (
        <Backdrop
          open={true}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "#fff",
          }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {errorMessage}
            <Link href="/signin" variant="body2">
              {"ログイン"}
            </Link>
          </Alert>
        </Backdrop>
      )}
      {isLoading && <BackDrop />}
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <img
                src={"Smash_Counter.svg"}
                style={logoStyle}
                alt="logo of sitemark"
                onClick={() => handleHomeClick()}
              />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <MenuItem
                  onClick={() => handleHomeClick()}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    ホーム
                  </Typography>
                </MenuItem>

                <MenuItem sx={{ py: "6px", px: "12px" }}>
                  <Link
                    href="https://forms.gle/vXS5VnzW94E8cnMM8"
                    variant="body2"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    お問い合わせ
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={() => handleTermUseClick()}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    利用規約
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              {isAuthenticated ? (
                <div>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleUserMenu}
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem disabled>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", py: 1 }}
                      >
                        {username}
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleMydataEditClick}>
                      マイデータ編集
                    </MenuItem>
                    <MenuItem onClick={handlePasswordChangeClick}>
                      パスワード変更
                    </MenuItem>
                    <MenuItem onClick={handleDeleteAccountClick}>
                      アカウント削除
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
                  </Menu>
                </div>
              ) : (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => handleSignInClick()}
                  >
                    ログイン
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => handleSignUpClick()}
                  >
                    新規登録
                  </Button>
                </>
              )}
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              {isAuthenticated ? (
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleDrawer(true)}
                >
                  <AccountCircle />
                </IconButton>
              ) : (
                <Button
                  variant="text"
                  color="primary"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                  sx={{ minWidth: "30px", p: "4px" }}
                >
                  <MenuIcon />
                </Button>
              )}

              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  ></Box>
                  <MenuItem onClick={() => handleHomeClick()}>ホーム</MenuItem>
                  <MenuItem>
                    <Link
                      href="https://forms.gle/vXS5VnzW94E8cnMM8"
                      variant="body2"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      お問い合わせ
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleTermUseClick()}>
                    利用規約
                  </MenuItem>
                  <Divider />

                  {isAuthenticated ? (
                    <div>
                      <MenuItem disabled>
                        <Typography variant="body1" color="black">
                          {username}
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="contained"
                          component="a"
                          target="_blank"
                          sx={{ width: "100%" }}
                          onClick={() => handleMydataEditClick()}
                        >
                          マイデータ編集
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="contained"
                          component="a"
                          target="_blank"
                          sx={{ width: "100%" }}
                          onClick={() => handlePasswordChangeClick()}
                        >
                          パスワード変更
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="contained"
                          component="a"
                          target="_blank"
                          sx={{ width: "100%" }}
                          onClick={() => handleDeleteAccountClick()}
                        >
                          アカウント削除
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="outlined"
                          component="a"
                          target="_blank"
                          sx={{ width: "100%" }}
                          onClick={() => handleLogout()}
                        >
                          ログアウト
                        </Button>
                      </MenuItem>
                    </div>
                  ) : (
                    <>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="contained"
                          component="a"
                          target="_blank"
                          sx={{ width: "100%" }}
                          onClick={() => handleSignUpClick()}
                        >
                          新規登録
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="outlined"
                          component="a"
                          target="_blank"
                          sx={{ width: "100%" }}
                          onClick={() => handleSignInClick()}
                        >
                          ログイン
                        </Button>
                      </MenuItem>
                    </>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
