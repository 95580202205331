import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import CircularProgress from "@mui/material/CircularProgress";
const Activate: React.FC = () => {
  const { uidb64, token } = useParams<{ uidb64: string; token: string }>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const { setIsAuthenticated, getUsername } = authContext!;
  const navigate = useNavigate();

  useEffect(() => {
    const activateAccount = async () => {
      try {
        const response = await axios.get(`/api/activate/${uidb64}/${token}/`);
        setSuccessMessage(response.data.message);
        setIsAuthenticated(true);
        await getUsername();
      } catch (error) {
        setErrorMessage(
          "アカウントの有効化に失敗しました。リンクが無効か期限切れです。"
        );
      } finally {
        setIsLoading(false);
      }
    };

    activateAccount();
  }, [uidb64, token]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          アカウント認証
        </Typography>
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isLoading && (
            <>
              <Typography variant="body1" ml={2}>
                認証中
              </Typography>
              <CircularProgress size={24} sx={{ ml: 2 }} />
            </>
          )}
          {successMessage && !isLoading && (
            <>
              <Alert severity="success" sx={{ width: "100%", mb: 2 }}>
                {successMessage}
              </Alert>
              <Button
                onClick={() => navigate("/")}
                variant="text"
                color="primary"
              >
                ホームへ戻る
              </Button>
            </>
          )}
          {errorMessage && !isLoading && (
            <>
              <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
                {errorMessage}
              </Alert>
              <Button
                onClick={() => navigate("/")}
                variant="text"
                color="primary"
              >
                ホームへ戻る
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Activate;
