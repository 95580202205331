import BarChartIcon from "@mui/icons-material/BarChart";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from "@mui/icons-material/GetApp";
import SearchIcon from "@mui/icons-material/Search";
import SummarizeIcon from "@mui/icons-material/Summarize";
import TransformIcon from "@mui/icons-material/Transform";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import React, { FC, useLayoutEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { HashLink as Link } from "react-router-hash-link";
import api from "../../api";
import {
  CountType,
  battleItems,
  BurstData,
  BurstedData,
  characterImages,
  CountData,
  gameDivItems,
  stageItems,
  levelItems,
  names,
  normalAttacks,
  outcomeItems,
  SearchResult,
  specialAttacksMap,
  Stats,
  otherCounts,
  initialCountData,
} from "../../common/constants";
import {
  getChartOptions,
  createPieChartData,
  pieChartOptions,
} from "../../common/graph";
import { scrollToSection } from "../../common/scroll";
import { CustomPagination } from "../../common/tablePagination";
import BackDrop from "./BackDrop";
import CountTypeToggleButtonGroup from "./CountTypeToggleGroup";
import Count from "./Count";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  calculateAverages,
  getMaxvalueObj,
  calculateBurstStats,
  calculateBurstedStats,
  countOccurrences,
  calculateRatePerUnitTime,
} from "../../common/calc";

const SearchForm: FC<{ username: string; editFlg: boolean }> = ({
  username,
  editFlg,
}) => {
  const [myCharacter, setMyCharacter] = useState<string[]>([]);
  const [opponentCharacter, setOpponentCharacter] = useState<string[]>([]);
  const [level, setLevel] = useState<string[]>([]);
  const [matchType, setMatchType] = useState<string[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [stage, setStage] = useState<string[]>([]);
  const [battleType, setBattleType] = useState<string[]>([]);
  const [outcome, setOutcome] = useState<string[]>([]);
  const [searchID, setSearchID] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedId, setSelectedId] = useState<Set<string>>(new Set());
  const [selectedData, setSelectedData] = useState<SearchResult[]>([]);

  const [selectedCharacter, setSelectedCharacter] = useState<string>("");
  const [counts, setCounts] = useState<CountData | undefined>(initialCountData);
  const [averageCountsArray, setAverageCountsArray] = useState<
    CountData[] | undefined
  >([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [displayedCountType, setDisplayedCountType] =
    useState<CountType>("normal");
  const [showChart, setShowChart] = useState(false);
  const [edit, setEdit] = useState(false);
  const [resultCount, setResultCount] = useState<number>(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [mergeId, setMergeId] = useState<string[]>([]);
  const [mergeIdArray, setMergeIdArray] = useState<string[][]>([]);
  const [maxValueObj, setMaxValueObj] = useState<{
    [key: string]: number;
  }>({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [burstStatsArray, setBurstStatsArray] = useState<Stats[]>([]);
  const [burstedStatsArray, setBurstedStatsArray] = useState<Stats[]>([]);
  const [burstAttackCountsArray, setBurstAttackCountsArray] = useState<
    {
      [key: string]: number;
    }[]
  >([]);
  const [burstedAttackCountsArray, setBurstedAttackCountsArray] = useState<
    {
      [key: string]: number;
    }[]
  >([]);
  const [burstConditionCountsArray, setBurstConditionCountsArray] = useState<
    {
      [key: string]: number;
    }[]
  >([]);
  const [burstedConditionCountsArray, setBurstedConditionCountsArray] =
    useState<
      {
        [key: string]: number;
      }[]
    >([]);
  const [isConverted, setIsConverted] = useState(false);

  const getSpecialAttacks = (): string[] =>
    results.length > 0 ? specialAttacksMap[selectedCharacter!] || [] : [];
  const countItems = displayedCountType.includes("normal")
    ? normalAttacks
    : displayedCountType === "userdef"
    ? selectedData.flatMap((item) => {
        return Object.keys(item.counts["userdef"].low);
      })
    : displayedCountType === "other"
    ? otherCounts
    : getSpecialAttacks();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      setSelectedId(new Set());
      setCounts(initialCountData);
      const response = await axios.post("/api/search/", {
        username: username,
        myCharacter: myCharacter.length === 0 ? null : myCharacter,
        opponentCharacter:
          opponentCharacter.length === 0 ? null : opponentCharacter,
        level: level.length === 0 ? null : level,
        matchType: matchType.length === 0 ? null : matchType,
        startDate,
        endDate,
        stage: stage.length === 0 ? null : stage,
        battleType: battleType.length === 0 ? null : battleType,
        outcome: outcome.length === 0 ? null : outcome,
        searchID: searchID.length === 0 || searchID[0] === "" ? null : searchID,
        keywords: keywords.length === 0 || keywords[0] === "" ? null : keywords,
      });
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching search results", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchResultCount = async () => {
    try {
      setIsLoadingCount(true);
      const response = await axios.post("/api/search/", {
        username: username,
        myCharacter: myCharacter.length === 0 ? null : myCharacter,
        opponentCharacter:
          opponentCharacter.length === 0 ? null : opponentCharacter,
        level: level.length === 0 ? null : level,
        matchType: matchType.length === 0 ? null : matchType,
        startDate,
        endDate,
        stage: stage.length === 0 ? null : stage,
        battleType: battleType.length === 0 ? null : battleType,
        outcome: outcome.length === 0 ? null : outcome,
        searchID: searchID.length === 0 || searchID[0] === "" ? null : searchID,
        keywords: keywords.length === 0 || keywords[0] === "" ? null : keywords,
      });
      setResultCount(response.data.length);
    } catch (error) {
      console.error("Error fetching result count", error);
    } finally {
      setIsLoadingCount(false);
    }
  };

  function getCharacterImage(characterName: string) {
    return characterImages[characterName] || characterName;
  }

  const handleSelectResult = (id: string) => {
    setShowChart(false);
    setEdit(false);
    setIsConverted(false);
    setSelectedId((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleShowChart = () => {
    if (showChart) {
      setShowChart(false);
      setIsConverted(false);
      return;
    }

    const selectedData = results.filter((result) => selectedId.has(result.id));

    if (selectedData.length === 0) {
      setErrorMessage("キャラクターが選択されていません。");
      scrollToSection("search-info");
      return;
    }

    const allSameCharacter = selectedData.every(
      (data) => data.my_character === selectedData[0].my_character
    );

    if (!allSameCharacter) {
      setErrorMessage(
        "チェックボックスのついた集計対象のキャラクターが異なります。"
      );
      scrollToSection("search-info");
      return;
    }

    if (
      selectedData[0].my_character === "ホムラ/ヒカリ" ||
      selectedData[0].my_character === "ジョーカー" ||
      selectedData[0].my_character === "ポケモントレーナー" ||
      selectedData[0].my_character === "ミェンミェン"
    ) {
      setDisplayedCountType("normal1");
    } else {
      setDisplayedCountType("normal");
    }

    const mergeId = selectedData.map((data) => data.id);
    const wkMergeIdArray = [mergeId];

    // 棒グラフ集計処理
    const countsArray = selectedData.map((data) => data.counts);
    const average = calculateAverages(countsArray);
    const wkAverageCountsArray = [average];
    const maxValueObj = getMaxvalueObj(countsArray);

    // 撃墜系統の処理
    const burstDataArray = selectedData.map((data) => data.burst_data);
    const burstedDataArray = selectedData.map((data) => data.bursted_data);
    const wkBurstStatsArray = [calculateBurstStats(burstDataArray)];
    const wkBurstedStatsArray = [calculateBurstedStats(burstedDataArray)];
    const wkBurstAttackCountsArray = [
      countOccurrences(burstDataArray, "burstAttack"),
    ];
    const wkBurstedAttackCountsArray = [
      countOccurrences(burstedDataArray, "burstedAttack"),
    ];
    const wkBurstConditionCountsArray = [
      countOccurrences(burstDataArray, "burstCondition"),
    ];
    const wkBurstedConditionCountsArray = [
      countOccurrences(burstedDataArray, "burstedCondition"),
    ];

    setErrorMessage(null);
    setSuccessMessage(null);
    setShowChart(true);
    setSelectedCharacter(selectedData[0].my_character);
    setSelectedData(selectedData);
    setAverageCountsArray(wkAverageCountsArray);
    setMaxValueObj(maxValueObj);
    setMergeIdArray(wkMergeIdArray);
    setBurstStatsArray(wkBurstStatsArray);
    setBurstedStatsArray(wkBurstedStatsArray);
    setBurstAttackCountsArray(wkBurstAttackCountsArray);
    setBurstedAttackCountsArray(wkBurstedAttackCountsArray);
    setBurstConditionCountsArray(wkBurstConditionCountsArray);
    setBurstedConditionCountsArray(wkBurstedConditionCountsArray);
  };

  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

  const handleEdit = () => {
    if (edit) {
      setEdit(false);
      return;
    }

    const selectedData = results.filter((result) => selectedId.has(result.id));

    if (selectedData.length === 0) {
      setErrorMessage("キャラクターが選択されていません。");
      scrollToSection("search-info");
      return;
    }

    if (selectedData.length > 1) {
      setErrorMessage("複数のキャラクターが選択されています。");
      scrollToSection("search-info");
      return;
    }

    if (
      selectedData[0].my_character === "ホムラ/ヒカリ" ||
      selectedData[0].my_character === "ジョーカー" ||
      selectedData[0].my_character === "ポケモントレーナー" ||
      selectedData[0].my_character === "ミェンミェン"
    ) {
      setDisplayedCountType("normal1");
    } else {
      setDisplayedCountType("normal");
    }

    setErrorMessage(null);
    setSuccessMessage(null);
    setEdit(true);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    const selectedData = results.filter((result) => selectedId.has(result.id));
    const selectIDList = selectedData.map((item) => {
      return item.id;
    });

    api
      .post("/api/delete-counts/", { selectIDList })
      .then((response) => {
        setIsLoading(false);
        setSuccessMessage(response.data.message);
        scrollToSection("search-info");
        fetchResultCount();
        const notDeletedData = results.filter(
          (result) => !selectedId.has(result.id)
        );
        setResults(notDeletedData);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          setIsLoading(false);
          setErrorMessage(
            error.response?.data.detail || "エラーが発生しました。"
          );
          scrollToSection("search-info");
        }
      });

    setOpenConfirmDialog(false);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleCountTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newCountType: CountType | null
  ) => {
    if (newCountType !== null) {
      setDisplayedCountType(newCountType);
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleMergeExecute = () => {
    const selectedMergeData = selectedData.filter((data) =>
      mergeId.includes(data.id)
    );
    const countsArray = selectedMergeData.map((data) => data.counts);
    const average = calculateAverages(countsArray);
    const wkAverageCountsArray = [...averageCountsArray!, average];

    const burstDataArray = selectedMergeData.map((data) => data.burst_data);
    const burstedDataArray = selectedMergeData.map((data) => data.bursted_data);
    const wkburstStatsArray = [
      ...burstStatsArray,
      calculateBurstStats(burstDataArray),
    ];
    const wkburstedStatsArray = [
      ...burstedStatsArray,
      calculateBurstedStats(burstedDataArray),
    ];
    const wkBurstAttackCountsArray = [
      ...burstAttackCountsArray,
      countOccurrences(burstDataArray, "burstAttack"),
    ];
    const wkBurstedAttackCountsArray = [
      ...burstedAttackCountsArray,
      countOccurrences(burstedDataArray, "burstedAttack"),
    ];
    const wkBurstConditionCountsArray = [
      ...burstConditionCountsArray,
      countOccurrences(burstDataArray, "burstCondition"),
    ];
    const wkBurstedConditionCountsArray = [
      ...burstedConditionCountsArray,
      countOccurrences(burstedDataArray, "burstedCondition"),
    ];

    setBurstStatsArray(wkburstStatsArray);
    setBurstedStatsArray(wkburstedStatsArray);
    setBurstAttackCountsArray(wkBurstAttackCountsArray);
    setBurstedAttackCountsArray(wkBurstedAttackCountsArray);
    setBurstConditionCountsArray(wkBurstConditionCountsArray);
    setBurstedConditionCountsArray(wkBurstedConditionCountsArray);
    setAverageCountsArray(wkAverageCountsArray);
    setMergeIdArray((prevMergeIdArray) => [...prevMergeIdArray, mergeId]);
    setMergeId([]);
    setOpenSnackbar(true);
  };

  const handleConvertButtonClick = () => {
    if (isConverted) {
      // 元のCountDataに戻す処理
      handleRestoreOriginalData();
    } else {
      // 単位時間あたりに変換する処理
      handleCalculateRatePerUnitTime();
    }

    // ボタンの表示を切り替え
    setIsConverted(!isConverted);
  };

  const handleCalculateRatePerUnitTime = () => {
    // 棒グラフ集計処理
    const selectedData = results.filter((result) => selectedId.has(result.id));
    const countsArray = selectedData.map((data) => data.counts);
    const timeArray = selectedData.map((data) => data.match_time);
    const convertedCountsArray = calculateRatePerUnitTime(
      countsArray,
      timeArray
    );
    // selectedDataの各要素にcorrectedCountsを適用
    const updatedSelectedData = selectedData.map((data, index) => ({
      ...data,
      counts: convertedCountsArray[index],
    }));

    const wkAverageCountsArray = mergeIdArray.map((item) => {
      const selectedMergeData = results.filter((result) =>
        item.includes(result.id)
      );
      const countsMergeArray = selectedMergeData.map((data) => data.counts);
      const timeMergeArray = selectedMergeData.map((data) => data.match_time);
      const convertedCountsMergeArray = calculateRatePerUnitTime(
        countsMergeArray,
        timeMergeArray
      );
      return calculateAverages(convertedCountsMergeArray);
    });

    // グラフ表示用にデータを補正する
    // 複数グラフを表示した際に軸の値を揃えるため、データの最大値を取得し空データとして加える
    const maxValue = getMaxvalueObj(convertedCountsArray);
    setSelectedData(updatedSelectedData);
    setAverageCountsArray(wkAverageCountsArray);
    setMaxValueObj(maxValue);
    setOpenSnackbar(true);
  };

  const handleRestoreOriginalData = () => {
    const selectedData = results.filter((result) => selectedId.has(result.id));

    // 棒グラフ集計処理
    const countsArray = selectedData.map((data) => data.counts);
    const wkAverageCountsArray = mergeIdArray.map((item) => {
      const selectedMergeData = results.filter((result) =>
        item.includes(result.id)
      );
      const countsMergeArray = selectedMergeData.map((data) => data.counts);
      return calculateAverages(countsMergeArray);
    });

    const maxValueObj = getMaxvalueObj(countsArray);

    setSelectedCharacter(selectedData[0].my_character);
    setSelectedData(selectedData);
    setAverageCountsArray(wkAverageCountsArray);
    setMaxValueObj(maxValueObj);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getChartData = (counts: CountData) => {
    return {
      labels: countItems,
      datasets: [
        {
          label: "～50%",
          data: countItems.map(
            (countName) => counts[displayedCountType]?.low[countName] || 0
          ),
          backgroundColor: "rgba(66,133,244,1)",
          borderWidth: 1,
          borderColor: "rgba(66,133,244,1)",
        },
        {
          label: "51%～100%",
          data: countItems.map(
            (countName) => counts[displayedCountType]?.middle[countName] || 0
          ),
          backgroundColor: "rgba(244, 180, 0, 1)",
          borderWidth: 1,
          borderColor: "rgba(244, 180, 0, 1)",
        },
        {
          label: "101%～",
          data: countItems.map(
            (countName) => counts[displayedCountType]?.high[countName] || 0
          ),
          backgroundColor: "rgba(234, 67, 53, 1)",
          borderWidth: 1,
          borderColor: "rgba(234, 67, 53, 1)",
        },
      ],
    };
  };

  const generateCSV = (headers: string[], rows: string[][]) => {
    const csvRows = [headers.join(",")];
    rows.forEach((row) => {
      csvRows.push(row.join(","));
    });
    const csvString = csvRows.join("\n");
    const bom = "\uFEFF"; // UTF-8 BOM
    return bom + csvString;
  };

  const handleDownloadCSV = () => {
    ReactGA.event({
      category: "click",
      action: "集計データDLボタン",
    });

    const zip = new JSZip();

    selectedData.forEach((data: SearchResult) => {
      const countheaders = [
        "集計対象",
        "回数(~50%)",
        "回数(51%~100%)",
        "回数(101%~)",
      ];
      const countrows = countItems.map((countName) => [
        countName,
        (data.counts![displayedCountType]?.low[countName] || 0).toString(),
        (data.counts![displayedCountType]?.middle[countName] || 0).toString(),
        (data.counts![displayedCountType]?.high[countName] || 0).toString(),
      ]);
      const csvCountContent = generateCSV(countheaders, countrows);
      zip.file(
        `${data.id}/${data.id}_${data.my_character}_集計データ.csv`,
        csvCountContent
      );

      const burstheaders = ["撃墜技", "撃墜状況", "撃墜%", "コメント"];
      const burstrows = data.burst_data.map((burstData) => [
        burstData.burstAttack,
        burstData.burstCondition,
        burstData.burstPercentage,
        burstData.burstcomment,
      ]);
      const csvBurstContent = generateCSV(burstheaders, burstrows);
      zip.file(
        `${data.id}/${data.id}_${data.my_character}_撃墜データ.csv`,
        csvBurstContent
      );

      const burstedheaders = ["被撃墜技", "被撃墜状況", "被撃墜%", "コメント"];
      const burstedrows = data.bursted_data.map((burstedData) => [
        burstedData.burstedAttack,
        burstedData.burstedCondition,
        burstedData.burstedPercentage,
        burstedData.burstedcomment,
      ]);
      const csvBurstedContent = generateCSV(burstedheaders, burstedrows);
      zip.file(
        `${data.id}/${data.id}_${data.my_character}_被撃墜データ.csv`,
        csvBurstedContent
      );
    });

    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, "counts_data.zip");
    });
  };

  useLayoutEffect(() => {
    fetchResultCount();
  }, [
    myCharacter,
    opponentCharacter,
    level,
    matchType,
    startDate,
    endDate,
    stage,
    battleType,
    outcome,
  ]);

  useLayoutEffect(() => {
    fetchResultCount();
  }, [
    myCharacter,
    opponentCharacter,
    level,
    matchType,
    startDate,
    endDate,
    stage,
    battleType,
    outcome,
  ]);

  return (
    <>
      <Helmet>
        <title>{"参照"}</title>
        <meta
          name="description"
          content={
            "スマブラSP(Super Smash Bros Ultimate)におけるキャラクターの振った技の回数の集計データの参照機能を提供します。"
          }
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="google-adsense-account" content="ca-pub-8034328441383028" />
      </Helmet>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          marginTop: "-50px",
        }}
      >
        <Box
          sx={{
            my: 4,
            textAlign: "center",
            padding: "2px",
            borderRadius: "8px",
          }}
        >
          {isLoading && <BackDrop />}
          <Typography variant="h6" gutterBottom sx={{ textAlign: "left" }}>
            検索条件
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>集計対象のキャラクター</InputLabel>
                <Select
                  multiple
                  value={myCharacter}
                  onChange={(e) => setMyCharacter(e.target.value as string[])}
                  renderValue={(selected) =>
                    selected.length === names.length
                      ? "全選択"
                      : (selected as string[]).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={myCharacter.includes(name)}
                        color="primary"
                        value={name}
                      />
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>対戦相手のキャラクター</InputLabel>
                <Select
                  multiple
                  value={opponentCharacter}
                  onChange={(e) =>
                    setOpponentCharacter(e.target.value as string[])
                  }
                  renderValue={(selected) =>
                    selected.length === names.length
                      ? "全選択"
                      : (selected as string[]).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={opponentCharacter.includes(name)}
                        color="primary"
                        value={name}
                      />
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel>レベル帯</InputLabel>
                <Select
                  multiple
                  value={level}
                  onChange={(e) => setLevel(e.target.value as string[])}
                  renderValue={(selected) =>
                    selected.length === levelItems.length
                      ? "全選択"
                      : (selected as string[]).join(", ")
                  }
                >
                  {levelItems.map((levelitem) => (
                    <MenuItem key={levelitem} value={levelitem}>
                      <Checkbox
                        checked={level.includes(levelitem)}
                        color="primary"
                        value={levelitem}
                      />
                      {levelitem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel>勝敗 (集計対象)</InputLabel>
                <Select
                  multiple
                  value={outcome}
                  onChange={(e) => setOutcome(e.target.value as string[])}
                  renderValue={(selected) =>
                    selected.length === outcomeItems.length
                      ? "全選択"
                      : (selected as string[]).join(", ")
                  }
                >
                  {outcomeItems.map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox
                        checked={outcome.includes(type)}
                        color="primary"
                        value={type}
                      />
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel>ステージ</InputLabel>
                <Select
                  multiple
                  value={stage}
                  onChange={(e) => setStage(e.target.value as string[])}
                  renderValue={(selected) =>
                    selected.length === stageItems.length
                      ? "全選択"
                      : (selected as string[]).join(", ")
                  }
                >
                  {stageItems.map((stageItem) => (
                    <MenuItem key={stageItem} value={stageItem}>
                      <Checkbox
                        checked={stage.includes(stageItem)}
                        color="primary"
                        value={stageItem}
                      />
                      {stageItem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel>対戦区分</InputLabel>
                <Select
                  multiple
                  value={matchType}
                  onChange={(e) => setMatchType(e.target.value as string[])}
                  renderValue={(selected) =>
                    selected.length === gameDivItems.length
                      ? "全選択"
                      : (selected as string[]).join(", ")
                  }
                >
                  {gameDivItems.map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox
                        checked={matchType.includes(type)}
                        color="primary"
                        value={type}
                      />
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="開始日"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="終了日"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel>対戦環境</InputLabel>
                <Select
                  multiple
                  value={battleType}
                  onChange={(e) => setBattleType(e.target.value as string[])}
                  renderValue={(selected) =>
                    selected.length === battleItems.length
                      ? "全選択"
                      : (selected as string[]).join(", ")
                  }
                >
                  {battleItems.map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox
                        checked={battleType.includes(type)}
                        color="primary"
                        value={type}
                      />
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  value={searchID}
                  label="ID"
                  onChange={(e) =>
                    setSearchID(e.target.value ? e.target.value.split(",") : [])
                  }
                  onBlur={fetchResultCount}
                  helperText="※カンマ(,)繋ぎで複数検索可能"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  value={keywords}
                  label="コメント内ワード"
                  onChange={(e) =>
                    setKeywords(e.target.value ? e.target.value.split(",") : [])
                  }
                  onBlur={fetchResultCount}
                  helperText="※カンマ(,)繋ぎで複数検索可能"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
            mb={4}
          >
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{ mx: 2, minWidth: "100px", height: "50px" }}
              startIcon={<SearchIcon />}
            >
              検索
            </Button>

            {isLoadingCount ? (
              <CircularProgress size={24} sx={{ ml: 2 }} />
            ) : (
              <Typography variant="body1" ml={2}>
                {resultCount}件
              </Typography>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Link smooth to="/count#guideline">
              集計ガイドラインについて
            </Link>
          </Box>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              pt: { xs: 10, sm: 15 },
              marginTop: "-100px",
            }}
            id="search-info"
          >
            {errorMessage && (
              <Alert severity="error" sx={{ margin: 5 }}>
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert
                severity="success"
                sx={{ width: "100%", mb: 2, marginTop: 5 }}
              >
                {successMessage}
              </Alert>
            )}
          </Container>

          {results.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom sx={{ textAlign: "left" }}>
                検索結果
              </Typography>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    "& .MuiTableCell-root": {
                      padding: "2px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: { xs: "0.6rem", sm: "0.8rem", md: "0.8rem" },
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>選択</TableCell>
                      <TableCell>No.</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>集計対象</TableCell>
                      <TableCell>対戦キャラ</TableCell>
                      <TableCell>レベル帯</TableCell>
                      <TableCell>勝敗</TableCell>
                      <TableCell>ステージ</TableCell>
                      <TableCell>対戦区分</TableCell>
                      <TableCell>対戦日</TableCell>
                      <TableCell>試合時間</TableCell>
                      <TableCell>対戦環境</TableCell>
                      <TableCell>コメント</TableCell>
                      <TableCell>対戦動画URL</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? results.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : results
                    ).map((result, index) => (
                      <TableRow
                        key={result.id}
                        sx={{
                          backgroundColor: selectedId.has(result.id)
                            ? "rgba(0, 123, 255, 0.1)" // 青みがかった背景色
                            : "inherit", // デフォルトの背景色
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            checked={selectedId.has(result.id)}
                            color="primary"
                            onChange={() => handleSelectResult(result.id)}
                          />
                        </TableCell>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{result.id}</TableCell>
                        <TableCell>
                          <img
                            src={getCharacterImage(result.my_character)}
                            alt={result.my_character}
                            style={{ width: "30px", height: "30px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <img
                            src={getCharacterImage(result.opponent_character)}
                            alt={result.opponent_character}
                            style={{ width: "30px", height: "30px" }}
                          />
                        </TableCell>
                        <TableCell>{result.level}</TableCell>
                        <TableCell>{result.outcome}</TableCell>
                        <TableCell>{result.stage}</TableCell>
                        <TableCell>{result.match_type}</TableCell>
                        <TableCell>{result.battle_date}</TableCell>
                        <TableCell>
                          {`${result.match_time.minutes}:${result.match_time.seconds}`}
                        </TableCell>
                        <TableCell>{result.battle_type}</TableCell>
                        <TableCell>{result.comments}</TableCell>
                        <TableCell>
                          <a
                            href={result.video_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {result.video_url}
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <CustomPagination
                count={results.length}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
              <Box display="flex" justifyContent="left" mt={4}>
                <Typography
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    fontSize: {
                      xs: "0.8rem", // Small screen size
                      sm: "0.9rem", // Medium screen size
                      md: "1.2rem", // Large screen size
                    },
                  }}
                >
                  ※同一キャラは複数選択してデータ表示可能です。複数選択時は小数切り上げの平均値を表示します。
                </Typography>
              </Box>
            </>
          )}

          {results.length > 0 && !editFlg && (
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                variant="contained"
                onClick={handleShowChart}
                sx={{ mx: 2, minWidth: "100px", height: "50px" }}
                disabled={selectedId.size === 0}
                startIcon={<BarChartIcon />}
              >
                {showChart ? "閉じる" : "データ表示"}
              </Button>
            </Box>
          )}

          {showChart && counts && results.length > 0 && (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenDialog}
                sx={{
                  mx: 2,
                  minWidth: "100px",
                  height: "50px",
                  marginTop: 4,
                }}
                startIcon={<SummarizeIcon />}
              >
                データをまとめる
              </Button>

              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDownloadCSV}
                >
                  <IconButton
                    aria-label="download"
                    sx={{ color: "white" }}
                    onClick={handleDownloadCSV}
                  >
                    <GetAppIcon />
                  </IconButton>
                  詳細データCSV
                </Button>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleConvertButtonClick} // クリック後に状態を切り替える
                >
                  <IconButton
                    aria-label="download"
                    sx={{ color: "white" }}
                    onClick={handleConvertButtonClick} // アイコンも同じクリック処理
                  >
                    <TransformIcon />
                  </IconButton>
                  {isConverted
                    ? "元のデータに変換"
                    : "単位時間(1分)あたりのデータに変換"}
                </Button>
              </Box>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // 3秒で自動的に閉じる
                onClose={handleCloseSnackbar}
                message="データを追加しました"
              />

              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // 3秒で自動的に閉じる
                onClose={handleCloseSnackbar}
                message="データの変換に成功しました"
              />

              <Dialog
                open={open}
                onClose={handleCloseDialog}
                fullWidth={true}
                maxWidth="xs"
              >
                <DialogTitle>データ選択</DialogTitle>
                <DialogContent>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>ID</InputLabel>
                    <Select
                      multiple
                      value={mergeId}
                      onChange={(e) => setMergeId(e.target.value as string[])}
                      renderValue={(selected) =>
                        (selected as string[]).join(", ")
                      }
                    >
                      {selectedData.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          <Checkbox
                            checked={mergeId.some((item) => item === data.id)}
                          />
                          {data.id}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="secondary">
                    キャンセル
                  </Button>
                  <Button onClick={handleMergeExecute} color="primary">
                    実行
                  </Button>
                </DialogActions>
              </Dialog>

              <Grid container spacing={4}>
                {selectedData.length > 1 &&
                  averageCountsArray!.map(
                    (data: CountData, averageIndex: number) => (
                      <Grid item xs={12} md={12} key={averageIndex}>
                        <Box sx={{ my: 4 }}>
                          <Typography variant="h6">
                            統計データ No.{averageIndex + 1}
                          </Typography>
                          <Typography variant="h6">
                            id:{mergeIdArray[averageIndex].join(",")}
                          </Typography>
                          <Typography variant="h6" mt={4}>
                            平均{isConverted ? " (データ変換後)" : ""}{" "}
                            {/* 状態に応じて表示を切り替え */}
                          </Typography>

                          <CountTypeToggleButtonGroup
                            myCharacter={selectedCharacter}
                            displayedCountType={displayedCountType}
                            handleCountTypeChange={handleCountTypeChange}
                            getSpecialAttacks={getSpecialAttacks}
                          />
                          <Box
                            sx={{
                              height: { xs: "1200px", md: "1200px" },
                              mb: 4,
                            }}
                          >
                            <Bar
                              data={getChartData(data)}
                              plugins={[ChartDataLabels]}
                              options={getChartOptions(
                                maxValueObj[displayedCountType]
                              )}
                            />
                          </Box>

                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="h6">撃墜%の統計</Typography>
                              <ul>
                                <li>
                                  平均値:
                                  {burstStatsArray[averageIndex].average}%
                                </li>
                                <li>
                                  最大値: {burstStatsArray[averageIndex].max}%
                                </li>
                                <li>
                                  最小値: {burstStatsArray[averageIndex].min}%
                                </li>
                                <li>
                                  中央値: {burstStatsArray[averageIndex].median}
                                  %
                                </li>
                              </ul>
                            </Grid>

                            <Grid item xs={6}>
                              <Typography variant="h6">
                                被撃墜%の統計
                              </Typography>
                              <ul>
                                <li>
                                  平均値:
                                  {burstedStatsArray[averageIndex].average}%
                                </li>
                                <li>
                                  最大値:
                                  {burstedStatsArray[averageIndex].max}%
                                </li>
                                <li>
                                  最小値:
                                  {burstedStatsArray[averageIndex].min}%
                                </li>
                                <li>
                                  中央値:
                                  {burstedStatsArray[averageIndex].median}%
                                </li>
                              </ul>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Box>
                                <Typography variant="h6">
                                  撃墜技の使用率
                                </Typography>
                                <Pie
                                  data={createPieChartData(
                                    burstAttackCountsArray[averageIndex]
                                  )}
                                  options={pieChartOptions}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box>
                                <Typography variant="h6">
                                  被撃墜技の使用率
                                </Typography>
                                <Pie
                                  data={createPieChartData(
                                    burstedAttackCountsArray[averageIndex]
                                  )}
                                  options={pieChartOptions}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box>
                                <Typography variant="h6">撃墜状況</Typography>
                                <Pie
                                  data={createPieChartData(
                                    burstConditionCountsArray[averageIndex]
                                  )}
                                  options={pieChartOptions}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box>
                                <Typography variant="h6">被撃墜状況</Typography>
                                <Pie
                                  data={createPieChartData(
                                    burstedConditionCountsArray[averageIndex]
                                  )}
                                  options={pieChartOptions}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    )
                  )}
              </Grid>
              {selectedData.map((data: SearchResult) => {
                return (
                  <Box sx={{ my: 4 }}>
                    <Box mt={10} mb={-5}>
                      <Typography variant="h6">
                        詳細データ{isConverted ? " (データ変換後)" : ""} id:
                        {data.id}
                      </Typography>
                    </Box>
                    <TableContainer
                      component={Paper}
                      sx={{ marginTop: 8, marginBottom: 2 }}
                    >
                      <Table
                        sx={{
                          "& .MuiTableCell-root": {
                            padding: "2px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: {
                              xs: "0.6rem",
                              sm: "0.8rem",
                              md: "0.8rem",
                            },
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>集計対象</TableCell>
                            <TableCell>対戦キャラ</TableCell>
                            <TableCell>レベル帯</TableCell>
                            <TableCell>勝敗</TableCell>
                            <TableCell>ステージ</TableCell>
                            <TableCell>対戦区分</TableCell>
                            <TableCell>対戦日</TableCell>
                            <TableCell>試合時間</TableCell>
                            <TableCell>対戦環境</TableCell>
                            <TableCell>コメント</TableCell>
                            <TableCell>対戦動画URL</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={data.id}>
                            <TableCell>{data.id}</TableCell>
                            <TableCell>
                              <img
                                src={getCharacterImage(data.my_character)}
                                alt={data.my_character}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </TableCell>
                            <TableCell>
                              <img
                                src={getCharacterImage(data.opponent_character)}
                                alt={data.opponent_character}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </TableCell>
                            <TableCell>{data.level}</TableCell>
                            <TableCell>{data.outcome}</TableCell>
                            <TableCell>{data.stage}</TableCell>
                            <TableCell>{data.match_type}</TableCell>
                            <TableCell>{data.battle_date}</TableCell>
                            <TableCell>
                              {`${data.match_time.minutes}:${data.match_time.seconds}`}
                            </TableCell>
                            <TableCell>{data.battle_type}</TableCell>
                            <TableCell>{data.comments}</TableCell>
                            <TableCell>
                              <a
                                href={data.video_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {data.video_url}
                              </a>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TableContainer
                      component={Paper}
                      sx={{ marginTop: 4, marginBottom: 2 }}
                    >
                      <Table
                        sx={{
                          "& .MuiTableCell-root": {
                            padding: "2px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: {
                              xs: "0.6rem",
                              sm: "0.8rem",
                              md: "0.8rem",
                            },
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: "15%" }}>撃墜技</TableCell>
                            <TableCell sx={{ width: "15%" }}>
                              撃墜状況
                            </TableCell>
                            <TableCell sx={{ width: "15%" }}>撃墜%</TableCell>
                            <TableCell sx={{ width: "55%" }}>
                              コメント
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.burst_data.map(
                            (burst: BurstData, index: number) => (
                              <TableRow key={`${data.id}-${index}`}>
                                <TableCell>{burst.burstAttack}</TableCell>
                                <TableCell>{burst.burstCondition}</TableCell>
                                <TableCell>{burst.burstPercentage}</TableCell>
                                <TableCell>{burst.burstcomment}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer
                      component={Paper}
                      sx={{ marginTop: 4, marginBottom: 2 }}
                    >
                      <Table
                        sx={{
                          "& .MuiTableCell-root": {
                            padding: "2px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: {
                              xs: "0.6rem",
                              sm: "0.8rem",
                              md: "0.8rem",
                            },
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: "15%" }}>
                              被撃墜技
                            </TableCell>
                            <TableCell sx={{ width: "15%" }}>
                              被撃墜状況
                            </TableCell>
                            <TableCell sx={{ width: "15%" }}>被撃墜%</TableCell>
                            <TableCell sx={{ width: "55%" }}>
                              コメント
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.bursted_data.map(
                            (bursted: BurstedData, index: number) => (
                              <TableRow key={`${data.id}-${index}`}>
                                <TableCell>{bursted.burstedAttack}</TableCell>
                                <TableCell>
                                  {bursted.burstedCondition}
                                </TableCell>
                                <TableCell>
                                  {bursted.burstedPercentage}
                                </TableCell>
                                <TableCell>{bursted.burstedcomment}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <CountTypeToggleButtonGroup
                      myCharacter={selectedCharacter}
                      displayedCountType={displayedCountType}
                      handleCountTypeChange={handleCountTypeChange}
                      getSpecialAttacks={getSpecialAttacks}
                    />
                    <div style={{ height: "1200px" }}>
                      <Bar
                        data={getChartData(data.counts)}
                        plugins={[ChartDataLabels]}
                        options={getChartOptions(
                          maxValueObj[displayedCountType]
                        )}
                      />
                    </div>
                  </Box>
                );
              })}
            </>
          )}
          {results.length > 0 && editFlg && (
            <>
              <Box mt={4}>
                <Button
                  variant="contained"
                  onClick={handleConfirmDelete}
                  sx={{ mx: 2, minWidth: "100px", height: "50px" }}
                  disabled={selectedId.size === 0}
                  color="error"
                  startIcon={<DeleteIcon />}
                >
                  削除
                </Button>
              </Box>
              <Box mt={4}>
                <Button
                  variant="contained"
                  onClick={handleEdit}
                  sx={{ mx: 2, minWidth: "100px", height: "50px" }}
                  disabled={selectedId.size === 0}
                  startIcon={<EditIcon />}
                >
                  {edit ? "閉じる" : "編集"}
                </Button>
              </Box>

              <Dialog
                open={openConfirmDialog}
                onClose={handleCloseConfirmDialog}
              >
                <DialogTitle>選択したデータを削除</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    選択したデータを削除してもよろしいですか？
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseConfirmDialog} color="secondary">
                    キャンセル
                  </Button>
                  <Button onClick={handleDelete} color="primary">
                    削除
                  </Button>
                </DialogActions>
              </Dialog>

              {edit && (
                <>
                  {results.map((result, index) => (
                    <>
                      {selectedId.has(result.id) && (
                        <Count
                          key={index}
                          editInfo={result}
                          editFlg={true}
                          editDisplayedCountType={displayedCountType}
                        />
                      )}
                    </>
                  ))}
                </>
              )}
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default SearchForm;
