import * as React from "react";
import { PaletteMode } from "@mui/material";
import AppAppBar from "../components/common/AppAppBar";
import Home from "../components/Home";
import Footer from "../components/common/Footer";

export default function HomePage() {
  const [mode, setMode] = React.useState<PaletteMode>("light");

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <>
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Home />
      <Footer />
    </>
  );
}
