export const chartOptions = {
  indexAxis: "y" as const,
  maintainAspectRatio: false,

  plugins: {
    datalabels: {
      display: true,
      labels: {
        value: {
          color: "#fff",
        },
      },
    },
    legend: {
      display: true,
    },
    tooltip: {
      enabled: true,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: false,
    },
    y: {
      grid: {
        display: false, // X軸の目盛り線を非表示
      },
    },
  },
};

export const getChartOptions = (maxXValue: number) => {
  return {
    indexAxis: "y" as const,
    maintainAspectRatio: false,

    plugins: {
      datalabels: {
        display: true,
        labels: {
          value: {
            color: "#fff",
          },
        },
      },
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
        max: maxXValue, // 引数で渡された値を使用
        min: 0,
      },
      y: {
        grid: {
          display: false, // X軸の目盛り線を非表示
        },
      },
    },
  };
};

// 円グラフデータを作成する関数
export const createPieChartData = (counts: { [key: string]: number }) => {
  if (!counts) {
    return {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    }; // counts が null や undefined の場合に空データを返す
  }

  return {
    labels: Object.keys(counts),
    datasets: [
      {
        data: Object.values(counts),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FFCD56",
          "#4DC0B5",
          "#FF6384",
          "#36A3EB",
        ],
      },
    ],
  };
};

// 円グラフオプションに割合を表示する設定を追加
export const pieChartOptions = {
  plugins: {
    datalabels: {
      formatter: (value: number, context: any) => {
        const total = context.dataset.data.reduce(
          (acc: number, val: number) => acc + val,
          0
        );
        const percentage = ((value / total) * 100).toFixed(1);
        return `${percentage}%`; // 割合を表示
      },
      color: "#fff", // ラベルの文字色
      font: {
        size: 15, // ラベルのサイズ
      },
    },
  },
};
