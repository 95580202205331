import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// 共通のスタイル設定
export const headingStyles = {
  fontSize: {
    xs: "1.2rem", // Small screen size
    sm: "2rem", // Medium screen size
    md: "2rem", // Large screen size
  },
};

export const textStyles = {
  mb: { xs: 2, sm: 4 },
  fontSize: {
    xs: "0.8rem", // Small screen size
    sm: "0.9rem", // Medium screen size
    md: "1.2rem", // Large screen size
  },
  color: "text.secondary",
};

export interface SectionProps {
  title: string;
  children: React.ReactNode;
}

// コンポーネント化
export const Section: React.FC<SectionProps> = ({ title, children }) => (
  <Box sx={{ marginTop: 5 }}>
    <Typography component="h2" color="text.primary" sx={headingStyles}>
      {title}
    </Typography>
    <Box sx={textStyles}>{children}</Box>
  </Box>
);
